// .keep-in-touch {
//     background-image: url("#{$cloudfrontpath}/enquiryformbg.jpg");
//     background-size: cover;
//     color: #fff;
//     padding: 50px 0 60px;
//     float: left;
//     width: 100%;
//     font-family: 'Poppins', sans-serif;
//     font-size: 16px;
// }
.keep-touch-content {
    p {
        font-size: 16px;
        margin-bottom: 20px;
        margin-top: 9px;
    }
}

.option_select {
    display: flex;
    padding: 0 15px 30px;
}

span {
    &.option_value.border-color {
        position: relative;
    }
}

.option_value {
    flex: 1 1 0;
    position: relative;
    margin-right: 33px;
}

.option_value,
.rate {
    display: inline-block;
}

.option_value input.relative {
    height: 47px;
    opacity: 0;
    position: relative;
    width: 100%;
    z-index: 9;
}

input.relative+label::before {
    content: "";
    display: inline-block;
    height: 44px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 41%;
    width: 52px;
    transform: translateY(-50%);
}

input[type="checkbox"]:checked+label::after {
    background: #08304a;
    border: 1px solid #fff;
    border-radius: 100%;
    color: #fff;
    content: "¬";
    display: inline-block;
    font-size: 19px;
    font-weight: 500;
    height: 28px;
    left: -15px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: -13px;
    transform: rotate(135deg);
    width: 28px;
    right: initial;
}

.option {
    display: block;
    font-size: 12px;
    padding-top: 0;
    text-align: center;
    font-weight: normal;
    background: #08304a;
    border: 1px solid #00aeef;
}

input[type="checkbox"]:checked+label,
input[type="checkbox"]:hover+label {
    border-color: #fff;
}

// .website-icon label::before,
// .application-icon label::before,
// .branding-icon label::before,
// .strategy-icon label::before,
// .digital label::before,
// .partnership label::before,
// .quotation label::before,
// .testing label::before {
//     background-position: center top;
//     background-repeat: no-repeat;
//     content: "";
//     display: block;
//     height: 38px;
//     left: 0px;
//     position: absolute;
//     right: 0;
//     top: 41%;
//     transform: translateY(-50%);
//     width: 50px;
//     margin: 0 auto;
// }
// .website-icon label::before {
//     background-image: url("#{$cloudfrontpath}/website.svg");
//     width: 47px;
//     height: 36px;
// }
// .application-icon label::before {
//     background-image: url("#{$cloudfrontpath}/application.svg");
// }
// .branding-icon ::before {
//     background-image: url("#{$cloudfrontpath}/branding.svg");
// }
// .strategy-icon label::before {
//     background-image: url("#{$cloudfrontpath}/stratagy.svg");
// }
// .digital label::before {
//     background-image: url("#{$cloudfrontpath}/digital-marketing.svg");
// }
// .partnership label::before {
//     background-image: url("#{$cloudfrontpath}/partnership.svg");
// }
// .quotation label::before {
//     background-image: url("#{$cloudfrontpath}/quotation.svg");
// }
// .testing label::before {
//     background-image: url("#{$cloudfrontpath}/testing.svg");
// }
// .website-icon input[type="checkbox"]:checked+label::before,
// .website-icon input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/website1.svg");
// }
// .application-icon input[type="checkbox"]:checked+label::before,
// .application-icon input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/application1.svg");
// }
// .branding-icon input[type="checkbox"]:checked+label::before,
// .branding-icon input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/branding1.svg");
// }
// .strategy-icon input[type="checkbox"]:checked+label::before,
// .strategy-icon input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/stratagy1.svg");
// }
// .digital input[type="checkbox"]:checked+label::before,
// .digital input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/digital-marketing1.svg");
// }
// .partnership input[type="checkbox"]:checked+label::before,
// .partnership input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/partnership1.svg");
// }
// .quotation input[type="checkbox"]:checked+label::before,
// .quotation input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/quotation1.svg");
// }
// .testing input[type="checkbox"]:checked+label::before,
// .testing input[type="checkbox"]:hover+label::before {
//     background-image: url("#{$cloudfrontpath}/testing1.svg");
// }
.describe-message {
    height: 48px;
    max-width: 100%;
    width: 100%;
    resize: none;
}

::-webkit-input-placeholder {
    color: #fff;
}

::-moz-placeholder {
    color: #fff;
}

:-ms-input-placeholder {
    color: #fff;
}

:-moz-placeholder {
    color: #fff;
}

.my-error-class::-webkit-input-placeholder {
    color: red;
}

.touch-option {
    margin-top: 29px;
}

.privacy-policy-block {
    display: inline;
}

.attach-icon span#cancelimg {
    float: right;
    background-color: #00adef;
    color: #fff;
    padding: 0px 6px;
    text-transform: capitalize;
    font-size: 14px;
    cursor: pointer;
}

.attach-icon span#cancelimg:hover {
    background-color: #337ab7;
}

.attach-icon img {
    float: left;
    padding-right: 8px;
}

.attach-icon span {
    float: left;
}

.attach-icon>img {
    left: 16px;
    position: absolute;
}

.file-full-block {
    position: relative;
    width: 50%;
    overflow: hidden;
}

.file-keep-touch {
    cursor: pointer;
    opacity: 0;
    position: relative;
    width: 77%;
    z-index: 8;
    float: left;
}

.attach-ifany {
    position: absolute;
    top: 0;
    z-index: 7;
    font-size: 12px;
    left: 30px;
}

.privacy-policy-block {
    span {
        padding-left: 11px;
        vertical-align: bottom;
        font-size: 12px;
        a {
            color: #ffffff;
            text-decoration: underline;
        }
    }
    img {
        margin-top: -2px;
    }
}

// .keep-in-touch {
//     .white-text {
//         color: #fff;
//     }
// }
.req-form {
    background: #00adef;
    border: medium none;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    margin-top: 58px;
    padding: 11px 39px;
    text-transform: uppercase;
    float: right;
}

.req-quote-btn {
    clear: both;
    display: block;
    text-align: center;
}


/* Spinner animation */

.req-quote-btn {
    .ladda-spinner {
        position: absolute;
        z-index: 2;
        display: inline-block;
        top: 50%;
        margin-top: 0;
        opacity: 0;
        pointer-events: none;
    }
}


/* Button label */

.req-quote-btn {
    .ladda-label {
        position: relative;
        z-index: 3;
    }
}


/* Progress bar */

.req-quote-btn {
    .ladda-progress {
        position: absolute;
        width: 0;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba( 0, 0, 0, 0.2);
        visibility: hidden;
        opacity: 0;
    }
}

.option {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    top: 0;
    line-height: 147px;
}

.option_value input.relative {
    height: 91px;
    margin: 0;
    display: block;
}

.full-option-parent {
    .chosen-container {
        &.chosen-container-single {
            .chosen-drop {
                width: 100%;
            }
        }
    }
}

.full-option-parent {
    .chosen-search {
        display: none;
    }
}

.full-option-parent {
    .chosen-container {
        .chosen-results {
            margin-right: 0px;
        }
    }
}

@media all and (-ms-high-contrast:none) {
    .option_value input.relative {
        width: 110px;
    }
    /* IE10 */
    *::-ms-backdrop,
    .option_value input.relative {
        width: 110px;
    }
    /* IE11 */
}

@media screen and (max-width:1680px) and (min-width: 992px) {
    .no-lineheight {
        line-height: 16px !important;
        display: inline-block;
        position: relative;
        top: 5px;
    }
}

@media screen and (max-width:1199px) and (min-width: 992px) {
    .file-full-block {
        width: 170px;
    }
}

@media screen and (max-width:991px) {
    .option_select span:nth-child(4) {
        margin: 0;
    }
    .option_select {
        padding: 0 0px 30px;
    }
}