html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%
}

body {
    margin: 0
}

article,
aside,
details,
figcaption,
figure,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background-color: transparent
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    margin: .67em 0;
    font-size: 2em
}

mark {
    color: #000;
    background: #ff0
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    height: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-appearance: textfield
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    padding: .35em .625em .75em;
    margin: 0 2px;
    border: 1px solid silver
}

legend {
    padding: 0;
    border: 0
}

textarea {
    overflow: auto
}

optgroup {
    font-weight: 700
}

table {
    border-spacing: 0;
    border-collapse: collapse
}

td,
th {
    padding: 0
}

@media print {
    *,
     :after,
     :before {
        color: #000 !important;
        text-shadow: none !important;
        background: 0 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important
    }
    a,
    a:visited {
        text-decoration: underline
    }
    a[href]:after {
        content: " (" attr(href) ")"
    }
    abbr[title]:after {
        content: " (" attr(title) ")"
    }
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: ""
    }
    blockquote,
    pre {
        border: 1px solid #999;
        page-break-inside: avoid
    }
    thead {
        display: table-header-group
    }
    img,
    tr {
        page-break-inside: avoid
    }
    img {
        max-width: 100% !important
    }
    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }
    h2,
    h3 {
        page-break-after: avoid
    }
    .navbar {
        display: none
    }
    .btn>.caret,
    .dropup>.btn>.caret {
        border-top-color: #000 !important
    }
    .label {
        border: 1px solid #000
    }
    .table {
        border-collapse: collapse !important
    }
    .table td,
    .table th {
        background-color: #fff !important
    }
    .table-bordered td,
    .table-bordered th {
        border: 1px solid #ddd !important
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

a {
    color: #337ab7;
    text-decoration: none
}

a:focus,
a:hover {
    color: #23527c;
    text-decoration: underline
}

a:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px
}

figure {
    margin: 0
}

img {
    vertical-align: middle
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
    display: block;
    max-width: 100%;
    height: auto
}

.img-rounded {
    border-radius: 6px
}

.img-thumbnail {
    display: inline-block;
    max-width: 100%;
    height: auto;
    padding: 4px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.img-circle {
    border-radius: 50%
}

hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

[role=button] {
    cursor: pointer
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-weight: 400;
    line-height: 1;
    color: #777
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 20px;
    margin-bottom: 10px
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
    font-size: 65%
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-size: 75%
}

.h1,
h1 {
    font-size: 36px
}

.h2,
h2 {
    font-size: 30px
}

.h3,
h3 {
    font-size: 24px
}

.h4,
h4 {
    font-size: 18px
}

.h5,
h5 {
    font-size: 14px
}

.h6,
h6 {
    font-size: 12px
}

p {
    margin: 0 0 10px
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4
}

@media (min-width: 768px) {
    .lead {
        font-size: 21px
    }
}

.small,
small {
    font-size: 85%
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.text-center {
    text-align: center
}

.text-justify {
    text-align: justify
}

.text-nowrap {
    white-space: nowrap
}

.text-lowercase {
    text-transform: lowercase
}

.text-uppercase {
    text-transform: uppercase
}

.text-capitalize {
    text-transform: capitalize
}

.text-muted {
    color: #777
}

.text-primary {
    color: #337ab7
}

a.text-primary:focus,
a.text-primary:hover {
    color: #286090
}

.text-success {
    color: #3c763d
}

a.text-success:focus,
a.text-success:hover {
    color: #2b542c
}

.text-info {
    color: #31708f
}

a.text-info:focus,
a.text-info:hover {
    color: #245269
}

.text-warning {
    color: #8a6d3b
}

a.text-warning:focus,
a.text-warning:hover {
    color: #66512c
}

.text-danger {
    color: #a94442
}

a.text-danger:focus,
a.text-danger:hover {
    color: #843534
}

.bg-primary {
    color: #fff;
    background-color: #337ab7
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: #286090
}

.bg-success {
    background-color: #dff0d8
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: #c1e2b3
}

.bg-info {
    background-color: #d9edf7
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: #afd9ee
}

.bg-warning {
    background-color: #fcf8e3
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: #f7ecb5
}

.bg-danger {
    background-color: #f2dede
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: #e4b9b9
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    border-bottom: 1px solid #eee
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 10px
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none
}

.list-inline>li {
    display: inline-block;
    padding-right: 5px;
    padding-left: 5px
}

dl {
    margin-top: 0;
    margin-bottom: 20px
}

dd,
dt {
    line-height: 1.42857143
}

dt {
    font-weight: 700
}

dd {
    margin-left: 0
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap
    }
    .dl-horizontal dd {
        margin-left: 180px
    }
}

abbr[data-original-title],
abbr[title] {
    cursor: help;
    border-bottom: 1px dotted #777
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

address {
    margin-bottom: 20px;
    font-style: normal;
    line-height: 1.42857143
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #c7254e;
    background-color: #f9f2f4;
    border-radius: 4px
}

pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px
}

pre code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-radius: 0
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 768px) {
    .container {
        width: 750px
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
}

body {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none
}

.site {
    overflow: visible
}

.header-bottom.sticky {
    position: fixed;
    background: #2a3756;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #fff
}

.sub-banner .contain-box .pro-head #typedtext.active,
.sub-banner .contain-box .pro-head .typedtext-bottom #typedtext-bottom {
    font-family: Poppins, sans-serif !important;
    font-weight: 300
}

.header-bottom .sticky-title {
    display: none
}

.header-bottom.sticky {
    z-index: 9999
}

.header-bottom.sticky .new-menu {
    padding: 3px 0
}

.header-bottom.sticky .new-menu .nav-menu>li>a {
    color: #FFF;
    font-size: 14px
}

.header-bottom.sticky .new-menu .nav-menu>li>a:hover::before,
.header-bottom.sticky .new-menu .nav-menu>li.active>a::before {
    color: #60c9e9
}

.header-bottom.sticky .new-menu .nav-menu>li>a:hover::after,
.header-bottom.sticky .new-menu .nav-menu>li.active>a::after {
    color: #60c9e9
}

.header-bottom.sticky .sticky-title {
    display: block;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 300;
    line-height: 24px;
    padding: 13px 0px;
    color: #fff;
    width: auto;
    float: left;
    margin: 0px
}

.header-bottom.sticky .navbar {
    margin-bottom: 0px
}

.header-bottom.sticky .logo-innerpage {
    width: 270px;
    text-align: left
}

.header-bottom.sticky .logo-innerpage>a {
    display: block
}

.header-bottom.sticky .logo-innerpage .site-title {
    display: none
}

.header-bottom.sticky .logo-innerpage .new-circles-360 {
    margin: 5px 0 0 35px
}

.header-bottom.sticky .logo-innerpage .new-circles-360::before {
    border-left: 2px solid #ffffff
}

.header-bottom.sticky .logo-innerpage .new-circle3 {
    border: 2px solid #fff
}

.header-bottom.sticky .logo-innerpage .new-circle1,
.header-bottom.sticky .logo-innerpage .new-circle2 {
    border: 1px solid #fff
}

.header-bottom.sticky .new-circles-360 {
    display: block
}

body.active .open-btn-toggle {
    background-image: url("#{$cloudfrontpath}/404/star_smile.svg");
    background-position: 7px 6px;
    background-repeat: no-repeat;
    background-size: 55% auto
}

body.active .open-header-block .open-btn-toggle {
    background-color: #000;
    background-image: url("#{$cloudfrontpath}/404/white-next.png");
    background-position: 14px 10px;
    background-repeat: no-repeat;
    background-size: 50%
}

body.active .left-icons a.chate {
    background-image: url("#{$cloudfrontpath}/404/javascript.png");
    background-position: -1104px 6px;
    display: block;
    height: 50px;
    width: 58px;
    z-index: 9
}

.icon-hover {
    display: none
}

.error {
    color: #f00
}

.opacity {
    opacity: 0;
    transition: all 0.3s
}

.opacity_active {
    opacity: 1
}

body.active .scroll-btn {
    background-image: url("#{$cloudfrontpath}/404/sevice-sprite-webcms.png");
    background-position: -1204px 1px;
    display: block;
    height: 40px;
    width: 40px;
    margin-left: 5px
}

.scroll-imgs {
    text-align: center
}

.scroll-imgs ul {
    padding: 93px 0 0 0;
    margin: 0px
}

.scroll-imgs ul li {
    color: #fff;
    display: inline-block;
    margin: 0;
    padding: 0 30px;
    min-width: 180px
}

.scroll-imgs ul span {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding-top: 12px;
    display: block
}

.bread-crumbs {
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    opacity: 0.89;
    padding: 10px 0;
    position: absolute;
    text-transform: uppercase;
    width: 100%;
    z-index: 9;
    font-size: 11px;
    bottom: 0
}

.bread-crumbs li {
    display: inline;
    padding: 4px;
    text-transform: uppercase;
    font-size: 11px
}

.bread-crumbs .container {
    position: relative;
    z-index: 5
}

.bread-crumbs span a:hover {
    color: #A7EBFF
}

.bread-crumbs span a:active {
    color: #A7EBFF
}

.bread-crumbs>.container>.row>ul {
    margin: 5px 0;
    padding: 0
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block
}

.owl-theme .owl-dots {
    line-height: 0;
    text-align: center
}

.owl-theme .owl-nav {
    line-height: 0;
    text-align: center
}

.section-title {
    width: 100%;
    float: left;
    text-align: center;
    padding: 0;
    margin: 10px 0 60px
}

.section-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.section-title h2 span {
    color: #ffde8c;
    position: relative;
    z-index: 100
}

.why-choose-title,
.latest-technology-title {
    width: 100%;
    float: left;
    text-align: center;
    padding: 0;
    margin: 0 0 40px
}

.why-choose-title h2,
.latest-technology-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    z-index: 100;
    padding: 0;
    margin: 0;
    float: left
}

.why-choose-title h2 span,
.latest-technology-title h2 span {
    color: #ffde8c;
    position: relative;
    z-index: 100
}

.works-list-title {
    width: 100%;
    float: left;
    text-align: center;
    padding: 0;
    margin: 0 0 40px 0
}

.works-list-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.oac-title {
    width: 100%;
    float: left;
    text-align: center;
    padding: 0;
    margin: 40px 0
}

.oac-title h6 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.oac-title h6::before {
    font-family: 'Poppins', sans-serif;
    font-size: 70px;
    line-height: 49px;
    font-weight: 600;
    color: rgba(185, 185, 185, 0.2);
    text-transform: uppercase;
    position: absolute;
    z-index: -1;
    content: '';
    display: inline-block;
    text-align: center;
    left: 50%;
    -ms-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.oac-title.presence h6::before {
    content: 'presence'
}

.oac-title.achievements h6::before {
    content: 'achieve'
}

.oac-title.clientreview h6::before {
    content: 'review'
}

.meet-o-heros-title {
    margin-bottom: 40px
}

.meet-o-heros-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.projects-title {
    margin-bottom: 40px
}

.projects-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.latest-work-title {
    margin-bottom: 40px
}

.latest-work-title h2 {
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 49px;
    font-weight: 600;
    color: #2a3756;
    text-transform: uppercase;
    position: relative;
    z-index: 10;
    padding: 0;
    margin: 0
}

.section-title,
.works-list-title,
.why-choose-title,
.meet-o-heros-title,
.projects-title,
.latest-work-title,
.latest-technology-title {
    position: relative
}

.section-title .h2-before,
.why-choose-title .h2-before,
.latest-technology-title .h2-before,
.works-list-title .h2-before,
.meet-o-heros-title .h2-before,
.projects-title .h2-before,
.latest-work-title .h2-before {
    color: rgba(255, 255, 255, 0.2);
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 80px;
    font-weight: 600;
    left: -5%;
    line-height: 49px;
    z-index: 10;
    text-align: center;
    position: absolute;
    text-transform: uppercase;
    width: 110%;
    height: 110%
}

.section-title .h2-before,
.works-list-title .h2-before,
.meet-o-heros-title .h2-before,
.projects-title .h2-before,
.projects-title .h2-before,
.latest-work-title .h2-before {
    color: rgba(185, 185, 185, 0.2)
}

section.meet-o-heros {
    width: 100%;
    float: left;
    text-align: center;
    margin: 0;
    padding: 80px 0;
    background: #ffffff;
    position: relative;
    z-index: 999
}

#marquee-sub {
    position: relative;
    background: url("#{$cloudfrontpath}/404/home-team-2018.jpg");
    width: 100%;
    height: 654px;
    -webkit-animation: left-marquee 70s linear infinite 2s normal;
    -moz-animation: left-marquee 70s linear infinite 2s normal;
    animation: left-marquee 70s linear infinite 2s normal
}

@-webkit-keyframes left-marquee {
    from {
        bacgkround-position: 0 0
    }
    to {
        background-position: -4000px 0
    }
}

@-moz-keyframes left-marquee {
    from {
        bacgkround-position: 0 0
    }
    to {
        background-position: -4000px 0
    }
}

@keyframes left-marquee {
    from {
        bacgkround-position: 0 0
    }
    to {
        background-position: -4000px 0
    }
}

#myCarousel-cr .owl-item .item-video::before {
    background: url("#{$cloudfrontpath}/404/diana-stanford.jpg") no-repeat center;
    background-color: #000;
    background-size: 90%;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1
}

#myCarousel-cr .owl-item:nth-child(2) .item-video::before {
    background-image: url("#{$cloudfrontpath}/404/raed.jpg")
}

#myCarousel-cr .owl-item:nth-child(3) .item-video::before {
    background-image: url("#{$cloudfrontpath}/404/chandler.jpg")
}

#myCarousel-cr .owl-item:nth-child(4) .item-video::before {
    background-image: url("#{$cloudfrontpath}/404/chris-makara.jpg")
}

.home-sect #myCarousel-cr .owl-item .item-video::before {
    background-size: cover
}

.home-sect .clientreview-text {
    margin: 40px 0 0
}

section.oac-section.home-sect .clientreview-text .oac-text span {
    padding: 20px 0 10px
}

.oac-box {
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    background: #fff;
    border: 1px solid #dadada;
    position: relative
}

.clientreview .slide {
    width: 90%;
    height: 270px !important
}

body.active .clientreview .carousel-indicators li {
    background: url("#{$cloudfrontpath}/404/po.png") no-repeat
}

body.active .clientreview .carousel-indicators li.active {
    background: url("#{$cloudfrontpath}/404/act-po.png") no-repeat
}

.clientreview .carousel-indicators li {
    display: flex;
    height: 13px;
    width: 12px;
    border: none;
    border-radius: 0;
    margin: 0 0 3px;
    padding: 0
}

.clientreview .carousel-indicators {
    bottom: inherit;
    right: 2.5%;
    margin-bottom: 2px;
    left: inherit;
    list-style: outside none none;
    margin-left: inherit;
    padding: 0;
    position: absolute;
    text-align: right;
    top: 50%;
    width: 4%;
    z-index: 15;
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.clientreview .carousel-indicators li.active {
    height: 13px;
    width: 12px;
    margin: 0 0 3px;
    padding: 0
}

.clientreview-text {
    margin: 17px 0 0;
    position: relative
}

.clientreview-text .oac-text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    color: #8b8b8b;
    margin: 0px;
    text-align: left;
    min-height: 240px;
    padding: 30px 15px
}

.clientreview-text .oac-text span {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 26px;
    font-weight: 600;
    color: #2a3756;
    padding: 20px 0 15px;
    text-align: left;
    float: left
}

.clientreview-text::before {
    position: absolute;
    height: 27px;
    width: 39px;
    top: -19px;
    left: 0
}

.clientreview-text::after {
    position: absolute;
    right: 0px;
    bottom: -18px;
    height: 27px;
    width: 39px
}

body.active .clientreview-text::before {
    content: url("#{$cloudfrontpath}/404/quote-header-1-before.png")
}

body.active .clientreview-text::after {
    content: url("#{$cloudfrontpath}/404/quote-header-2-after.png")
}

.sidr {
    display: block;
    position: fixed;
    top: 0;
    height: 100%;
    z-index: 97;
    width: 260px;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: Poppins, serif;
    font-size: 15px;
    background: #1d253c;
    color: #fff
}

.wprmenu_menu_ul_main {
    width: 100%;
    padding-top: 40px;
    min-height: 100vh;
    flex-wrap: wrap;
    align-items: flex-start
}

#simple-menu {
    background: #138ab9;
    cursor: pointer;
    display: block;
    overflow: hidden;
    padding: 12px;
    position: fixed;
    top: 0px;
    z-index: 99999;
    left: 85%;
    width: 45px;
    height: 42px;
    border-radius: 5px
}

#simple-menu .wprmenu_icon {
    float: left;
    height: 22px;
    margin-right: 0px;
    overflow: hidden;
    position: relative;
    width: 20px
}

#simple-menu .wprmenu_icon span {
    background: #f2f2f2;
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    transition: all 0.01s ease-in-out 0s;
    width: 20px
}

#simple-menu .wprmenu_ic_1 {
    top: 0px
}

#simple-menu .wprmenu_ic_2 {
    top: 7px
}

#simple-menu .wprmenu_ic_3 {
    top: 14px
}

#simple-menu .wprmenu_icon_par:hover {
    color: #606060
}

#simple-menu .wprmenu_icon_par {
    color: #cfcfcf
}

#simple-menu .wprmenu_icon_par {
    color: #cfcfcf;
    cursor: pointer;
    display: block;
    float: left;
    left: 0px;
    padding: 13px 14px 10px;
    position: absolute;
    top: 0px;
    width: 46px
}

#simple-menu [data-icon]::before,
#simple-menu .wprmenu_icon::before {
    font-family: wprmenu;
    font-variant: normal;
    font-weight: 400;
    line-height: 1;
    text-transform: none
}

.icon_default.wprmenu_icon_par::before {
    padding-left: 6px;
    content: "";
    text-align: center;
    background-image: url(#{$cloudfrontpath}/404/arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    background-size: 45% auto;
    width: 18px;
    height: 18px;
    display: block;
    margin: 0px auto;
    background-position: center
}

.icon_default.wprmenu_par_opened::before {
    content: "";
    background-image: url(#{$cloudfrontpath}/404/arrow.svg);
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    background-position: top
}

.bread-crumbs i {
    margin: 0 5px
}

.scroll-imgs li {
    transition: all 0.5s ease;
    vertical-align: top
}

.scroll-imgs li a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 300
}

.scroll-imgs li a:hover {
    text-decoration: none
}

.scroll-imgs ul li.active a span {
    color: #14a9e1
}

.scroll-imgs ul li.active a svg * {
    stroke: #14a9e1
}

.scroll-imgs ul li.abt a svg * {
    stroke: #fff;
    fill: transparent
}

.scroll-imgs ul li.abt.active a svg * {
    stroke: #14a9e1;
    fill: transparent
}

.scroll-imgs li:hover {
    transform: scale(0.9)
}

.scroll-imgs.other-imgs li {
    width: 18%
}

#sidr.active {
    left: 0
}

.scroll-btn {
    animation-duration: 1.5s !important
}

.modal {
    z-index: 99999
}

.open-hlp-box #alert-success {
    color: #fff
}

.heder-social-icons {
    padding: 0 15px 30px 15px;
    display: block;
    align-self: flex-end;
    width: 100%
}

.heder-social-icons a {
    height: 28px;
    width: 28px;
    margin: 0 10px;
    background: url(#{$cloudfrontpath}/404/2019/home/social-icon-header.svg) no-repeat;
    display: inline-block
}

.heder-social-icons a:first-child {
    margin-left: 0
}

.heder-social-icons .facebook-icon {
    background-position: 0 0
}

.heder-social-icons .linkedin-icon {
    background-position: -99px 0
}

.heder-social-icons .twitter-icon {
    background-position: -49px 0
}

.heder-social-icons .instagram-icon {
    background-position: 0px -35px
}

.heder-social-icons .youtube-icon {
    background-position: -49px -35px
}

.header-buttons {
    padding: 30px 15px 30px 15px;
    display: block
}

.header-buttons .button {
    color: #fff;
    display: inline-block;
    padding: 3px 16px 3px 3px;
    border-radius: 17px;
    background-color: #5eb5e7;
    font-size: 14px;
    line-height: normal
}

.header-buttons .button+.button {
    margin-left: 10px
}

.header-buttons .button span {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-clip: padding-box;
    background-size: cover;
    background: url(#{$cloudfrontpath}/404/2019/home/social-icon-header.svg) no-repeat;
    background-color: #1d253c
}

.header-buttons .current-opening span {
    background-position: -150px -1px
}

.header-buttons .life-at-vc span {
    background-position: -200.5px -1px
}

.header-privacy-policy {
    padding: 0 15px 30px 15px;
    display: block;
    color: #fff
}

.header-privacy-policy span {
    display: block;
    font-size: 12px;
    margin-bottom: 5px
}

.header-privacy-policy a {
    text-decoration: none;
    outline: 0;
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    padding: 0 0 0 5px
}

.header-privacy-policy a.privacy {
    position: relative;
    padding: 0 5px 0 0
}
.header-privacy-policy a.sitemap{
    padding-left: 0;
}

.header-privacy-policy a.privacy:before {
    position: absolute;
    content: "";
    height: 10px;
    right: -3px;
    top: 10px;
    width: 1px;
    transform: translateY(-50%);
    background-color: #fff
}

label.option.my-error-class-input {
    border-color: red
}

#email.error {
    color: #fff
}

.sec-footer .error::-moz-placeholder {
    color: red
}

.sec-footer .error:-ms-input-placeholder {
    color: red
}

.sec-footer .error:-moz-placeholder {
    color: red
}

.sec-footer .error::-webkit-input-placeholder {
    color: red
}

.my-error-class::-moz-placeholder {
    color: red
}

.my-error-class:-ms-input-placeholder {
    color: red
}

.my-error-class:-moz-placeholder {
    color: red
}

.my-error-class::-webkit-input-placeholder {
    color: red
}

.my-error-class,
.error,
.getintouchcls input.my-error-class,
.right .form-main input.my-error-class,
.right .form-main textarea.my-error-class {
    color: red;
    border-color: red;
    background-color: #fff;
    display: block;
    text-align: left
}

.my-error-class::-moz-placeholder {
    color: red
}

.error::-moz-placeholder {
    color: red
}

.my-error-class input:active::-moz-placeholder {
    color: red
}

.my-error-class input:active::-ms-input-placeholder {
    color: red
}

.my-error-class input:active::-webkit-input-placeholder {
    color: red
}

input.error:active::-moz-placeholder {
    color: red
}

input.error:active::-ms-input-placeholder {
    color: red
}

input.error:active::-webkit-input-placeholder {
    color: red
}

input.error:focus::-moz-placeholder {
    color: red
}

input.error:focus::-ms-input-placeholder {
    color: red
}

input.error:focus::-webkit-input-placeholder {
    color: red
}

.req-form:hover {
    background-color: #337ab7
}

.textbox.my-error-class,
.sec-footer .onr-of-us .textbox.error {
    background: #fff;
    border-color: red;
    color: red
}

label.my-error-class {
    display: none !important
}

input.textbox.my-error-class:focus::-moz-placeholder {
    color: red
}

label.error {
    display: none !important
}

.oac-box #myCarousel-cr-t .carousel-indicators li {
    margin: 0px
}

.oac-box #myCarousel-cr-t .carousel-indicators .active {
    margin: 0px
}

.thanku-header .header-bottom {
    padding-top: 10px
}

.thanku-header .navbar {
    margin-bottom: 0
}

.thanku-header .new-menu {
    padding: 25px 0 0
}

#getintouchsub {
    cursor: pointer
}

#getintouchsub:hover {
    background: rgba(42, 55, 86, 0.5);
    color: #fff
}

.req-form {
    position: relative
}

#letsconnect {
    position: relative
}

.loader-spin {
    display: none
}

section.top-part svg {
    max-width: 100%
}

.trending-img .img-one a,
.trending-img .img-two a {
    display: block
}

.trending-img .img-two ul li a svg {
    max-width: 85%
}

.background-thread svg {
    object-fit: cover;
    width: 100%;
    vertical-align: bottom
}

#myCarousel-cr .owl-item .item-video::before {
    display: none
}

#myCarousel-cr iframe {
    width: 100%
}

.new-home #myCarousel-cr .owl-item img {
    width: 100%
}

#myCarousel-cr .owl-item img {
    width: auto;
    margin: 0 auto;
    display: table
}

#myCarousel-cr .youtube,
#myCarousel-cr .youtube2,
#myCarousel-cr .youtube3,
#myCarousel-cr .youtube4,
#myCarousel-cr .youtube1 {
    background: #000
}

.file-full-block input[type=file] {
    left: -100px
}

.textbox,
.chosen-container.chosen-container-single .chosen-single {
    background: #08304a;
    border: 1px solid #019cd8;
    color: #fff;
    margin: 9px 0;
    padding: 13px 10px;
    width: 100%;
    height: 45px;
    box-shadow: none;
    border-radius: 0px
}

.chosen-container.chosen-container-single {
    width: 100% !important;
    color: #C0C0C0
}

.chosen-container.chosen-container-single .chosen-single {
    padding: 0px;
    font-size: 16px;
    line-height: 45px;
    padding: 0 10px
}

.chosen-container.chosen-container-single .chosen-drop {
    top: 88%;
    width: calc(100% + 100px);
    background: #08304a;
    box-shadow: 0px;
    border-color: #00a6df
}

.chosen-container-single .chosen-search input[type="text"] {
    border-color: #00a6df;
    color: #fff
}

.chosen-container .chosen-results li.active-resultm,
.chosen-container .chosen-results li:hover,
.chosen-container .chosen-results li.result-selected,
.chosen-container .chosen-results li.highlighted {
    background: #00a6df
}

.chosen-container-single .chosen-single span {
    margin-right: 0
}

.chosen-container .chosen-results li.disabled-result {
    display: none
}

.chosen-container .chosen-results {
    padding: 0
}

.chosen-container .chosen-results li {
    color: #fff
}

.chosen-container-single .chosen-single div b {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
    transform: rotate(-90deg);
    vertical-align: middle;
    display: inline-block
}

.revenue-sign .chosen-container.chosen-container-single .chosen-single {
    background: #2c2c2c;
    border: 1px solid #fff;
    width: 99%;
    height: 34px;
    margin: 0px 0 9px
}

.revenue-sign .chosen-results {
    margin-right: 0px
}

// .hlp-box a.chosen-single span {
//     font-size: 13px;
//     margin: 7px 0 0;
//     font-weight: 100
// }
.revenue-sign .chosen-container.chosen-container-single .chosen-drop {
    width: 99%;
    background: #2c2c2c;
    border-color: #fff
}

.revenue-sign .chosen-container-single .chosen-single div b {
    transform: rotate(-90deg) translateX(100%)
}

.revenue-sign .chosen-container .chosen-results li.active-result {
    text-align: left
}

.revenue-sign .chosen-search {
    display: none
}

.my-error-class+.chosen-container .chosen-single {
    border-color: red
}

.my-error-class+.chosen-container.chosen-container-single .chosen-single {
    color: red;
    background: #fff
}

.my-error-class+.chosen-container-single .chosen-single div b {
    border-right-color: red
}

#requestquotation .my-error-class+.chosen-container .chosen-single {
    border-color: red
}

#requestquotation .my-error-class+.chosen-container.chosen-container-single .chosen-single {
    color: red;
    background: #fff
}

#requestquotation .my-error-class+.chosen-container-single .chosen-single div b {
    border-right-color: red
}

#requestquotation .my-error-class+.chosen-container-single .chosen-single span {
    color: red
}

.stdrt0 {
    fill: #fff
}

.stdrt1 {
    fill: #00AAFF
}

.cookies_popup {
    position: fixed;
    bottom: 2px;
    left: 0px;
    right: 0px;
    width: 96%;
    max-width: 900px;
    margin: 0px auto;
    background: #55555a;
    border-radius: 1px;
    z-index: 9999;
    padding: 10px;
    display: none
}

.cookies_popup p {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    width: 94%;
    float: left;
    margin: 0px
}

.cookies_popup p a {
    color: #fff
}

.cookies_popup a.close_btn {
    float: right;
    font-size: 16px;
    color: #fff;
    z-index: 999;
    position: relative;
    cursor: pointer
}

@media (min-width: 1921px) {
    .header-bottom {
        width: 100% !important
    }
}

@media (min-width: 1620px) and (max-width: 1920px) {
    .header-bottom {
        width: 100% !important
    }
}

@media only screen and (max-width: 1680px) and (min-width: 1280px) {
    .open-btn-toggle {
        background-position: 7px 0px
    }
}

@media screen and (max-width: 1620px) and (min-width: 1260px) {
    .new-home {
        overflow: hidden
    }
    .scale-page {
        transform-origin: 0 0;
        width: 1620px;
        overflow-x: hidden;
        height: auto !important
    }
    .stopscroll {
        overflow: hidden
    }
    .new-circles-360 {
        transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1)
    }
}

@media screen and (max-width: 1620px) and (min-width: 1280px) {
    .new-home {
        overflow: hidden
    }
    .scale-page {
        transform-origin: 0 0;
        width: 1620px;
        overflow-x: hidden;
        height: auto !important
    }
    .stopscroll {
        overflow: hidden
    }
}

@media only screen and (max-width: 1366px) {
    .site-title>img {
        max-width: 80%
    }
    .header-bottom.sticky .new-menu {
        padding: 3px 25px 3px 0
    }
    .clientreview-text .oac-text span {
        float: none
    }
}

@media only screen and (max-width: 1280px) {
    body.active .open-btn-toggle {
        background-size: 70% auto;
        background-position: 10px 9px
    }
}

@media only screen and (max-width: 1259px) {
    .header-bottom {
        width: 100% !important
    }
}

@media only screen and (max-width: 1200px) {
    .new-header.sticky .new-menu {
        padding: 0px
    }
    .block-sub-menu .caption,
    .we-re-hiring h3,
    .text-box>h3 a {
        font-size: 15px
    }
}

@media only screen and (max-width: 1199px) {
    .client-box,
    .clientreview-text {
        width: 49%
    }
    .oac-section.home-sect .clientreview-text {
        float: right
    }
    .home-sect .clientreview-text {
        margin: 20px 0px 0px
    }
    .home-sect #myCarousel-cr .owl-item .item-video::before {
        background-size: contain
    }
    #myCarousel-cr .owl-item .item-video::before {
        background-size: 100%
    }
    .home-sect .oac-title {
        margin: 40px 0px
    }
    .scroll-imgs ul li {
        padding: 0 32px;
        min-width: 150px
    }
}

@media only screen and (max-width: 1024px) {
    .new-circles-360 {
        margin-left: 0
    }
    .sub-page .logo-innerpage {
        width: 22%
    }
    .header-bottom .new-menu .nav-menu>li {
        margin: 0 5px
    }
    .header-bottom .site-title>img {
        max-width: 80%
    }
    .menu-images-icon .text-box>h3 a {
        font-size: 16px
    }
    .menu-images-icon .text-box p {
        font-size: 13px
    }
    .scroll-imgs ul {
        margin: 0 auto;
        display: inline-block
    }
    .section-title .h2-before,
    .why-choose-title .h2-before,
    .latest-technology-title .h2-before,
    .works-list-title .h2-before,
    .meet-o-heros-title .h2-before,
    .projects-title .h2-before,
    .latest-work-title .h2-before {
        font-size: 70px
    }
    .section-title h2,
    .works-list-title h2,
    .why-choose-title h2,
    .latest-technology-title h2,
    .meet-o-heros-title h2,
    .projects-title h2,
    .latest-work-title h2 {
        font-size: 30px
    }
    #contact-us .scroll-imgs ul {
        width: 100%
    }
}

@media only screen and (max-width: 980px) {
    .header-bottom .new-menu .nav-menu>li {
        margin: 0px
    }
    .header-bottom.sticky .new-menu .nav-menu>li>a {
        font-size: 13px
    }
    .header-bottom.sticky .logo-innerpage .new-circles-360 {
        margin: 5px 0 0 30px
    }
    .header-bottom.sticky .sticky-title {
        font-size: 30px
    }
    .sub-page .logo-innerpage {
        width: 23%
    }
    .header-bottom .new-menu .nav-menu>li>a {
        font-size: 14px
    }
    #contact-us .scroll-imgs ul li {
        float: none;
        margin: 0;
        padding: 0 5px
    }
    #contact-us .scroll-imgs.cus-scroll-con li:nth-child(2) {
        width: 38%
    }
    .keepintouchs .chosen-container.chosen-container-single .chosen-drop {
        width: 100%
    }
}

@media only screen and (max-width: 979px) {
    .sub-page .logo-innerpage {
        margin-left: 0;
        width: 29%
    }
    .scroll-imgs ul li {
        padding: 0 25px
    }
    .section-title .h2-before,
    .why-choose-title .h2-before,
    .latest-technology-title .h2-before,
    .works-list-title .h2-before,
    .meet-o-heros-title .h2-before,
    .projects-title .h2-before,
    .latest-work-title .h2-before {
        line-height: 48px;
        font-size: 55px
    }
    .section-title h2,
    .works-list-title h2,
    .why-choose-title h2,
    .latest-technology-title h2,
    .meet-o-heros-title h2,
    .projects-title h2,
    .latest-work-title h2 {
        font-size: 26px
    }
    .scroll-imgs ul span {
        font-size: 13px
    }
}

@media only screen and (max-width: 800px) {
    .sub-page .new-menu {
        display: none
    }
    .sub-page .logo-innerpage {
        width: 100%
    }
    .section-title .h2-before,
    .why-choose-title .h2-before,
    .latest-technology-title .h2-before,
    .works-list-title .h2-before,
    .meet-o-heros-title .h2-before,
    .projects-title .h2-before,
    .latest-work-title .h2-before {
        line-height: 48px;
        font-size: 46px
    }
    .section-title h2,
    .works-list-title h2,
    .why-choose-title h2,
    .latest-technology-title h2,
    .meet-o-heros-title h2,
    .projects-title h2,
    .latest-work-title h2 {
        font-size: 22px
    }
    .scroll-imgs ul li {
        padding: 0 15px
    }
    .cus-scroll-con ul li {
        width: 30%
    }
}

@media only screen and (max-width: 768px) {
    .carousel-indicators {
        bottom: 10px
    }
}

@media only screen and (max-height: 700px) {
    #be-a-partner .scroll-imgs ul {
        margin: 0;
        padding: 40px 0 0
    }
}

.scale-page {
    position: relative;
    z-index: 99
}

body.active .top-right-footer ul li a i.fa-pinterest:before,
body.active .top-right-footer ul li a i.fa-instagram:before {
    display: none
}

.loader-wrapper {
    position: fixed;
    height: 100vh;
    z-index: 99999999;
    overflow: hidden;
    background: #ffff;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.test-loader {
    stroke-dasharray: 900;
    stroke-dashoffset: 0;
    animation: dash 3s linear infinite
}

@keyframes dash {
    to {
        stroke-dashoffset: 900
    }
}

.logo-design {
    position: absolute;
    width: 84px;
    top: 52%;
    left: 0;
    right: 0;
    margin: 0px auto;
    transform: translateY(-50%)
}

.loaded .loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out
}

#myqoutes {
    display: none
}

@media (min-width: 1367px) {
    .header-bottom.sticky .navbar-collapse.collapse {
        margin-right: 100px
    }
}

@media screen and (max-width: 1366px) and (min-width: 1280px) {
    .header-bottom.sticky .navbar-collapse.collapse {
        margin-right: 80px
    }
}

@media screen and (max-width: 1279px) and (min-width: 1025px) {
    .header-bottom.sticky .navbar-collapse.collapse {
        margin-right: 20px
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .header-bottom.sticky .navbar-collapse.collapse {
        margin-right: 20px
    }
}

body {
    width: 100%;
    padding: 0;
    margin: 0;
    float: left
}

section {
    overflow: hidden
}

.padding-lft-rght {
    padding-right: 0 !important;
    padding-left: 0 !important
}

.mrgn-lft-rght {
    margin-right: 0;
    margin-left: 0
}

.navbar-brand {
    height: auto;
    padding: 0
}

.new-home .our-client {
    display: none !important
}

.header-bottom {
    width: 100%;
    z-index: 999;
    position: absolute
}

.header-bottom.sticky {
    position: fixed;
    background: #2a3756;
    left: 0;
    right: 0;
    top: 0
}

.menu {
    position: relative;
    z-index: 9
}

nav.container {
    position: static
}

.navbar-default {
    border: medium none;
    background-color: rgba(42, 55, 86, 0)
}

.home-page .logo-header {
    width: auto;
    margin: 0 auto;
    padding: 0 15px
}

.home-page .logo-innerpage {
    float: left;
    width: 100%;
    text-align: center
}

.home-page .logo-innerpage>a {
    display: inline-block;
    vertical-align: middle
}

.home-page .new-circles-360 {
    display: none
}

.home-page .new-menu {
    display: none
}

.home-page.sticky .new-menu {
    display: block
}

.sub-page .logo-header {
    width: auto;
    margin: 0 auto;
    padding: 0 15px
}

.sub-page .logo-innerpage {
    float: left;
    width: 25%;
    text-align: left;
    position: relative;
}

.sub-page .logo-innerpage>a {
    display: block
}

.sub-page .new-circles-360 {
    display: block
}

.sub-page .new-menu {
    display: block
}

.sub-page.sticky .new-menu {
    display: block
}

.site-title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    margin: 0;
    padding: 0;
    max-width: 100%;
    float: left
}

.site-title>img {
    max-width: 100%;
    width: auto
}

.sub-page .site-title>img {
    max-width: 80%
}

.menu-container {
    margin: 0 auto;
    width: 100%
}

.header-info-right {
    float: right;
    padding: 0 5px
}

.inner-header-right {
    float: right
}

.navbar-nav {
    float: left;
    margin: 0
}

.nav-menu li a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase
}

.wr-megamenu-container ul.wr-mega-menu {
    z-index: 9999;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%
}

.wr-megamenu-container ul.wr-mega-menu>li {
    height: 100%;
    float: left
}

.wr-megamenu-container ul.wr-mega-menu li {
    list-style: none
}

.wr-megamenu-container li {
    border: 0;
    display: inline-block;
    position: relative
}

.wr-megamenu-container ul.nav-menu li.home-icon a {
    background: url(#{$cloudfrontpath}/404/home-icon.png) center 3px no-repeat rgba(0,
    0,
    0,
    0) !important;
    padding: 10px 14px !important;
    margin-top: 15px;
    transition: all 0s ease 0s !important
}

.wr-megamenu-container .current-menu-ancestor>a,
.wr-megamenu-container .current-menu-item>a,
.wr-megamenu-container .current_page_ancestor>a,
html body .wr-megamenu-container .current_page_item>a {
    color: #fff !important;
    font-weight: 700 !important;
    font-style: normal
}

.nav-menu .menu-item:last-child .menu-item-link {
    border: 1px solid #14a9e1;
    border-radius: 5px;
    color: #14a9e1;
    margin: 10px 0 10px 10px;
    padding: 5px;
    transition: all 1s ease 0s
}

.wr-megamenu-container .nav-menu .menu-item:last-child .menu-item-link:hover {
    border-color: #fff;
    color: #fff !important
}

.wr-mega-menu li:first-child .menu_title {
    display: none
}

.wr-megamenu-container ul.wr-mega-menu li.wr-megamenu-item:hover>a.menu-item-link {
    background: none !important
}

.wr-megamenu-container .current-menu-ancestor>a,
.wr-megamenu-container .current-menu-item>a,
.wr-megamenu-container .current_page_ancestor>a,
html body .wr-megamenu-container .current_page_item>a {
    color: #fff !important;
    font-weight: 700 !important;
    font-style: normal
}

.mega-menu-content {
    color: #fff;
    font-size: 14px;
    margin: 20px 0 0;
    text-align: center;
    border-bottom: 6px solid #138ab9;
    padding-bottom: 15px
}

html body .wr-element-submenu .caption {
    padding: 0 0;
    width: 100%
}

.jsn-bootstrap3 .row {
    padding: 0 10px !important
}

html body .we-re-hiring>h3 {
    margin: 0;
    padding: 0
}

.wr-megamenu-container ul.wr-mega-menu li.wr-megamenu-item:hover>a.menu-item-link {
    background-color: none !important;
    color: #fff
}

.primary_214 .wr-mega-menu>li.focus>a,
.primary_214 .wr-mega-menu>li:hover>a {
    background: 0 0 !important
}

.submenu-items li a {
    text-transform: none !important
}

.cd-headline,
.project-needs-text,
.text-box>h3,
.we-re-hiring h3 {
    text-transform: uppercase
}

.submenu-items li:last-child {
    border-bottom: none
}

.text-box {
    margin: 0;
    color: #FFFFFF;
    float: left
}

.text-box>h3 {
    margin-top: 10px
}

.text-box>h3 a {
    margin: 0;
    padding: 0;
    color: #3cc0f2;
    font-size: 20px;
    font-weight: 500
}

.menu-images-icon .text-box a:hover {
    color: #FFFFFF
}

.text-box>p {
    line-height: 0px
}

.text-box br {
    display: none
}

.images-box {
    float: left;
    margin: 0 15px 0 0
}

.text-box p {
    font-size: 15px;
    padding: 3px 0 0
}

.menu-images-icon {
    float: left;
    margin: 5px 0px 10px
}

.mb-20 {
    margin-bottom: 20px
}

.mt-48 {
    margin-top: 48px
}

.mb-15 {
    margin-bottom: 15px
}

.mega-menu-content {
    color: #fff;
    font-size: 15px;
    margin: 25px 0 20px;
    text-align: center;
    padding: 15px;
    background: #343f5b;
    border: none
}

.mega-menu-content p {
    margin: 0
}

html body .wr-element-submenu .caption {
    padding: 5px 0;
    width: 100%;
    margin-bottom: 5px
}

.jsn-bootstrap3 .row {
    padding: 0 10px !important
}

html body .we-re-hiring>h3 {
    margin: 0;
    padding: 0
}

@keyframes menushine {
    100% {
        background-position: 172px 0px
    }
}

.par-img {
    background-position: 0px 0px
}

.vir-img {
    background-position: -70px 0px
}

.blg-img {
    background-position: -364px 0px
}

.project-needs-img {
    background: url("#{$cloudfrontpath}/404/mega-menu-svg.svg") no-repeat;
    width: 66px;
    height: 65px
}

.img-one {
    background-position: -153px 0
}

.img-two {
    background-position: -231px 0
}

.img-three {
    background-position: -311px 0
}

.margin-line {
    padding: 10px 0
}

.project-needs {
    float: left;
    width: 100%;
    margin: 25px 0 0
}

.project-needs ul {
    margin: 0;
    padding: 0
}

.project-needs li {
    display: inline-block !important;
    width: auto !important;
    vertical-align: top
}

.home-page .project-needs li,
.sub-page .project-needs li {
    margin: 0 45px 0 0
}

.project-needs li:last-child {
    margin: 0 0 0 10px !important
}

.project-needs-img {
    float: left
}

.project-needs-text {
    color: #ffffff;
    display: inline-block;
    font-weight: 500;
    margin: 0;
    padding: 10px 15px;
    vertical-align: middle
}

.home-page .project-needs-text,
.sub-page .project-needs-text {
    font-size: 20px
}

.sub-page .mega-menu-content br {
    display: none
}

.project-needs-img {
    text-align: center
}

.get-started-bt {
    float: left;
    margin: 0 0 20px;
    padding: 0
}

.get-started-bt a {
    background: #14a9e1;
    margin: 0;
    padding: 12px 20px !important;
    font-size: 20px !important;
    border-radius: 5px;
    transition: 1s;
    color: #1d253c !important;
    font-weight: 400
}

.get-started-bt a:hover {
    background: #1186b2 !important;
    color: #ffffff !important;
    transition: 1s
}

ul.nav-menu ul a,
.nav-menu ul ul a {
    color: #ffff;
    margin: 0;
    width: 200px
}

#simple-menu {
    display: none;
    background-color: rgba(0, 0, 0, 0);
    right: 5%;
    top: 5px;
    left: initial
}

.menuOpened a#simple-menu {
    left: 250px;
    top: 10px;
    z-index: 999999;
    background-color: #5eb5e7;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    padding: 4px 4px 4px 6px
}

.menuOpened #simple-menu .wprmenu_icon span {
    height: 3px;
    width: 16px
}

.new-home #simple-menu .wprmenu_icon {
    transform: translateY(-150%);
    transition: all 0.4s ease 0s
}

.new-home #simple-menu.active .wprmenu_icon {
    transform: translateY(0%)
}

#sidr.wprmenu_levels a.wprmenu_parent_item {
    border: none;
    margin-left: 0
}

.new-virtual-tour-icon::before {
    border-left: 2px solid rgba(225, 225, 225, 0.3);
    content: "";
    height: 50%;
    left: 24px;
    position: absolute;
    top: 37px;
    width: 1px
}

.new-virtual-tour-icon {
    padding: 34px 0 0 40px;
    position: relative;
    float: left
}

.new-virtual-tour-icon img {
    width: 45px
}

@keyframes rotatex {
    from {
        transform: rotatex(0deg)
    }
    to {
        transform: rotatex(360deg)
    }
}

@keyframes rotatey {
    from {
        transform: rotatey(0deg)
    }
    to {
        transform: rotatey(360deg)
    }
}

@keyframes rotatez {
    from {
        transform: rotatez(0deg)
    }
    to {
        transform: rotatez(360deg)
    }
}

@-webkit-keyframes rotatex {
    from {
        -webkit-transform: rotatex(0deg)
    }
    to {
        -webkit-transform: rotatex(360deg)
    }
}

@-webkit-keyframes rotatey {
    from {
        -webkit-transform: rotatey(0deg)
    }
    to {
        -webkit-transform: rotatey(360deg)
    }
}

@-webkit-keyframes rotatez {
    from {
        -webkit-transform: rotatez(0deg)
    }
    to {
        -webkit-transform: rotatez(360deg)
    }
}

@-moz-keyframes rotatex {
    from {
        -webkit-transform: rotatex(0deg)
    }
    to {
        -webkit-transform: rotatex(360deg)
    }
}

@-moz-keyframes rotatey {
    from {
        -webkit-transform: rotatey(0deg)
    }
    to {
        -webkit-transform: rotatey(360deg)
    }
}

@-moz-keyframes rotatez {
    from {
        -webkit-transform: rotatez(0deg)
    }
    to {
        -webkit-transform: rotatez(360deg)
    }
}

.new-circle3 {
    animation-duration: 4.5s;
    animation-iteration-count: infinite;
    animation-name: rotatey;
    animation-timing-function: linear;
    border: 2px solid #fff;
    border-radius: 50%;
    height: 39px;
    position: absolute;
    top: 0;
    width: 39px
}

.home-link>span {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    left: 11px;
    position: absolute;
    top: 14px
}

.new-circles-360 {
    background-color: transparent;
    border-radius: 50%;
    float: left;
    // height: 39px;
    // margin: 35px 0 0 0;
    // position: relative;
    // width: 39px
    width: 40px;
    position: absolute;
    left: 172px;
    top: 39%;
    height: 39px;
}

.new-circles-360::before {
    border-left: 2px solid #fff;
    content: "";
    height: 50%;
    left: -17px;
    position: absolute;
    top: 11px;
    width: 1px
}

.new-circle1 {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: rotatex;
    animation-timing-function: linear;
    border: 1px solid #fff;
    border-radius: 50%;
    height: 35px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 35px
}

.new-circle2 {
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: rotatey;
    animation-timing-function: linear;
    border: 1px solid #fff;
    border-radius: 50%;
    height: 35px;
    left: 2px;
    position: absolute;
    top: 2px;
    width: 35px
}

.new-virtuval-tour-link {
    width: 44px;
    height: 44px;
    position: absolute;
    z-index: 9
}

.header-bottom {
    padding-top: 20px
}

.new-menu {
    padding: 40px 0
}

.header-bottom .new-menu .nav-menu>li>a {
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    text-transform: uppercase;
    padding: 10px 0;
    color: #00a6df;
    position: relative
}

.header-bottom .new-menu .nav-menu>li>a:focus,
.header-bottom .new-menu .nav-menu>li>a:hover {
    background-color: transparent;
    text-decoration: none;
    color: #fff
}

.header-bottom .new-menu .nav-menu>li>a::before {
    content: "[";
    color: #fff;
    margin-right: 15px;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px)
}

.header-bottom .new-menu .nav-menu>li>a::after {
    content: "]";
    color: #fff;
    margin-left: 15px;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px)
}

.header-bottom .new-menu .nav-menu>li>a::before,
.header-bottom .new-menu .nav-menu>li>a::after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
    -ms-transition: transform 0.3s ease 0s, opacity 0.2s ease 0s;
    transition: transform 0.3s ease 0s, opacity 0.2s ease 0s
}

.header-bottom .new-menu .nav-menu>li {
    margin: 0 5px
}

.header-bottom .new-menu .nav-menu>li:last-child {
    margin: 0 0 0 12px
}

.header-bottom .new-menu .nav-menu>li.active>a {
    color: #fff
}

.header-bottom .new-menu .navbar-nav .nav-menu>li:hover>a {
    color: #fff !important
}

.header-bottom .new-menu .nav-menu>li.active>a::before,
.header-bottom .new-menu .nav-menu>li:hover>a::before {
    opacity: 1;
    transform: translateX(0px)
}

.header-bottom .new-menu .nav-menu>li.active>a::after,
.header-bottom .new-menu .nav-menu>li:hover>a::after {
    opacity: 1;
    transform: translateX(0px)
}

.wr-megamenu-container ul.wr-mega-menu li.menu-item-has-children>a::after {
    display: inline-block
}

// .coffee-cup-block .layer {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: #000;
//     opacity: 0.7;
//     display: none;
//     z-index: -1
// }
// .coffee-cup-block.open-header-block.active .layer {
//     display: block
// }
// .hlp-box+.layer {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: #000;
//     opacity: 0.7;
//     display: none;
//     z-index: 999
// }
// .hlp-box.open-hlp-box.active+.layer {
//     display: block
// }
// .submit .hlp-box.open-hlp-box.active+.layer {
//     display: none
// }
body .layer1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
    display: none;
    z-index: 99999
}

.home-page.sticky .logo-innerpage .site-title {
    position: absolute;
    left: -99999999999px
}

#particle-slider {
    display: none
}

.menuOpened {
    height: 100%;
    overflow: hidden
}

html.menuOpened body {
    overflow: hidden
}

#particle-slider {
    display: block !important
}

.header-bottom.home-page.sticky .logo-innerpage .site-title {
    display: block !important
}

.home-page.sticky .logo-innerpage .site-title {
    left: -99999px
}

.side-info {
    clear: both;
    display: inline-block
}

.side-info-vdo {
    clear: both;
    margin-top: 12px
}

#videoModal {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0
}

#videoModal .popup-inner {
    position: absolute;
    padding: 15px;
    background: #fff;
    max-width: 80vw;
    max-height: 80vh;
    width: 880px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

#videoModal .popup-inner .close-clvdo {
    float: right;
    color: #000;
    font-size: 19px;
    margin: 0 0 10px;
    line-height: 1;
    position: relative
}

#videoModal .popup-inner iframe {
    width: 100%;
    max-height: 70vh
}

.corporate-test .jsn-bootstrap3 .row {
    padding: 0px !important
}

html body .corporate-test .wr-element-submenu .caption {
    pointer-events: none
}

@media (min-width: 1921px) {
    .header-bottom {
        width: 100% !important
    }
}

@media screen and (max-width: 1680px) {
    // .coffee-cup-block.open-header-block.active {
    //     height: 94%
    // }
}

@media screen and (min-width: 1280px) {
    .new-home .container,
    .home-page .container,
    .container {
        width: 1366px;
        margin: 0px auto
    }
}

@media only screen and (max-width: 1259px) {
    .new-home .container,
    .home-page .container,
    .container {
        width: 100%;
        max-width: 100%;
        padding: 0 20px
    }
    .home-page .project-needs li,
    .sub-page .project-needs li {
        margin: 0 55px 0 0
    }
    .serv-hire .project-needs li {
        margin: 0 40px 0 0
    }
    .images-box {
        margin: 0 4px 0 0
    }
    #bs-example-navbar-collapse-1 .get-started-bt a {
        padding: 12px 11px !important;
        font-size: 18px !important
    }
    .home-page .project-needs li,
    .sub-page .project-needs li {
        margin: 0 25px 0 0
    }
    .serv-hire .project-needs li {
        margin: 0 30px 0 0
    }
    .inner-header-right {
        float: left
    }
    .home-page .project-needs-text,
    .sub-page .project-needs-text {
        font-size: 18px
    }
    .project-needs {
        margin: 25px 0 20px
    }
}

@media only screen and (max-width: 1199px) {
    .sub-page .logo-innerpage {
        width: 26%
    }
    .header-bottom .new-menu .nav-menu>li {
        margin: 0 5px
    }
    .header-bottom .new-menu .nav-menu>li>a {
        font-size: 15px
    }
    .header-bottom .new-menu .nav-menu>li>a::before {
        margin-right: 10px
    }
    .header-bottom .new-menu .nav-menu>li>a::after {
        margin-left: 10px
    }
    .header-bottom .new-menu .nav-menu>li {
        margin: 0 0px
    }
    .header-bottom .new-menu .nav-menu>li {
        margin: 0
    }
    .inner-header-right {
        float: none
    }
    .navbar-collapse {
        padding: 0
    }
    .header-bottom .new-menu .nav-menu>li>a {
        font-size: 16px
    }
    .wr-element-submenu .submenu-items a {
        font-size: 14px
    }
    .text-box>h3 a {
        font-size: 16px
    }
    .text-box p {
        font-size: 12px
    }
    .home-page .project-needs-text,
    .sub-page .project-needs-text {
        font-size: 16px
    }
    .project-needs-text {
        padding: 10px
    }
    .home-page .project-needs li,
    .sub-page .project-needs li {
        margin: 0
    }
    .new-header.sticky .new-menu .navbar-nav>div {
        margin-top: 3px
    }
}

@media only screen and (max-width: 1024px) {
    #simple-menu {
        display: block
    }
    .menu-container {
        display: none
    }
    #sidr.wprmenu_levels ul li ul li ul li.wprmenu_parent_item_li .wprmenu_icon_par,
    #sidr.wprmenu_levels ul li ul li.wprmenu_parent_item_li a.wprmenu_parent_item {
        margin-left: 70px
    }
    #sidr.wprmenu_levels a.wprmenu_parent_item {
        margin-left: 25px
    }
}

@media only screen and (max-width: 1023px) {
    .sub-page .logo-innerpage {
        width: 50%
    }
}

.ideas span,
.international span,
.iso span,
.support span,
.pricing span,
.security span {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: 10px;
    font-family: Poppins, sans-serif
}

.work_together_img {
    text-align: center;
    margin-top: 20px;
    position: relative;
    height: 250px
}

.work_together_social {
    text-align: center;
    font-size: 0
}

.work_together_social .title {
    width: 100%;
    display: inline-block;
    background: #0d99d0;
    margin: 0
}

.work_together_social .title h2 {
    font-weight: 500;
    font-size: 26px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin: 8px 0 6px;
    font-family: Poppins, sans-serif
}

.work_together_social .social_box {
    display: inline-block;
    margin: 0 18px
}

.work_together_social .social_box span {
    color: #11345a;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    vertical-align: middle;
    line-height: 33px;
    margin: 0 0 0 10px;
    display: inline-block
}

.work_together_social .social_box:first-child {
    margin: 0 0 0 22px
}

.work_together_social .social_box .icons {
    border: 2px solid #0d99cf;
    width: 34px;
    height: 34px;
    display: table;
    line-height: 26px;
    text-align: center;
    float: left
}

.work_together_social .social_box .icons div {
    display: table-cell;
    vertical-align: middle
}

.work_together_social .social_box a:hover {
    text-decoration: none
}

.work_together_social .boxs {
    border: 2px solid #6ec2e3;
    border-top: none;
    background-color: #fff;
    padding: 22px 0;
    overflow: auto;
    text-align: center
}

.floting-icon {
    animation: bouncing 3s infinite ease-in-out
}

.ideas {
    width: 54%;
    position: absolute;
    top: 140px;
    text-align: center;
    animation-delay: 1.3s;
    text-align: center
}

.international {
    width: 54%;
    position: absolute;
    top: -21px;
    text-align: center;
    text-align: center
}

.iso {
    width: 54%;
    position: absolute;
    top: 158px;
    text-align: center;
    animation-delay: 1.15s;
    text-align: center
}

.support {
    width: 54%;
    position: absolute;
    top: 285px;
    text-align: center;
    animation-delay: 2.15s;
    text-align: center
}

.pricing {
    width: 54%;
    position: absolute;
    top: -68px;
    text-align: center;
    left: -51%
}

.security {
    width: 54%;
    position: absolute;
    top: -70px;
    text-align: center;
    right: -43%
}

.blue-bg {
    background: #10355a
}

.left-f {
    left: 0
}

.right-f {
    right: 0
}

.scale-page {
    margin-bottom: 594px;
    background: #fff
}

#padding-animate {
    padding-bottom: 1px
}

#fooball-inner {
    background-color: #11345a;
    width: 100%;
    position: fixed;
    height: 555px;
    bottom: 0;
    z-index: 2
}

.footer-body {
    width: 100%
}

.footer-body #canvas {
    margin: 0 auto;
    width: 100%
}

.banner {
    z-index: 3;
    background-color: #888 !important
}

.sub-banner {
    z-index: 3;
    background-color: #888
}

#wrap {
    position: relative;
    z-index: 3
}

#content {
    width: 100%;
    position: absolute;
    z-index: 1;
    clear: both
}

#content p {
    font-size: 1.25rem;
    letter-spacing: .02rem;
    margin-bottom: 1.3rem
}

@keyframes bouncing {
    0% {
        transform: translate(0, 0)
    }
    65% {
        transform: translate(0, 15px)
    }
    100% {
        transform: translate(0, 0)
    }
}

.spine {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
}

.spine .animated.wow {
    visibility: hidden !important
}

.spine2 {
    right: 12%
}

.spine2 #ficon2 {
    opacity: 0
}

.spine2 img {
    position: absolute;
    bottom: 164px;
    left: 44.5%
}

.spine2.active #ficon2 {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 43%;
    opacity: 1
}

.spine3 {
    left: 22.5%
}

.spine3 #ficon3 {
    opacity: 0
}

.spine3 img {
    position: absolute;
    bottom: 45px;
    right: 43%
}

.spine3.active #ficon3 {
    opacity: 1;
    position: absolute;
    bottom: 0;
    right: 40.5%;
    opacity: 1
}

.spine4 {
    left: 19%
}

.spine4 #ficon4 {
    opacity: 0
}

.spine4 img {
    position: absolute;
    bottom: 159px;
    left: 42.5%
}

.spine4.active #ficon4 {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 40%;
    opacity: 1
}

.spine1 #ficon1 {
    opacity: 0
}

.spine1 img {
    position: absolute;
    bottom: 30px;
    left: 31.5%
}

.spine1.active #ficon1 {
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 29.5%;
    opacity: 1
}

@media (max-width: 1680px) {
    .pricing {
        left: -36%
    }
}

@media (max-width: 1440px) {
    .pricing {
        left: -33%
    }
}

@media (max-width: 1024px) {
    .work_together_social .social_box {
        width: 7%;
        margin: 0 5% 10px
    }
    .work_together_social .social_box:first-child {
        margin: 0 5% 10px
    }
    .work_together_social .social_box span {
        display: none
    }
    .work_together_social .boxs {
        padding: 12px 0 3px
    }
    .pricing {
        left: 6%;
        top: -100px
    }
    .security {
        top: -90px;
        right: 0
    }
    .international {
        top: 20px
    }
    .spine1 img {
        left: 27.5%
    }
    .spine1.active #ficon1 {
        left: 25%
    }
    .spine2 img {
        left: 43.5%
    }
    .spine2.active #ficon2 {
        left: 41%
    }
    .spine3 img {
        right: 33%
    }
    .spine3.active #ficon3 {
        right: 29.5%
    }
    .spine4 img {
        left: 47.5%
    }
    .spine4.active #ficon4 {
        left: 43.5%
    }
    .ideas {
        right: 5%;
        top: 155px
    }
    .support {
        top: 265px
    }
    .iso {
        top: 148px
    }
}

@media (max-width: 991px) {
    .floting-icon {
        float: left;
        width: 25%;
        position: relative;
        top: 0;
        min-height: 140px
    }
    .work_together_img {
        display: none
    }
    .work_together_img img {
        display: none !important
    }
    .xs-offset-3 {
        margin: 0
    }
    .support {
        top: 0;
        top: 0
    }
    .iso {
        top: auto;
        bottom: 10px
    }
    .ideas {
        top: 0;
        right: 0
    }
    .left-f {
        left: 0
    }
    .swing {
        display: none
    }
    .work_together_social .social_box {
        width: auto;
        margin: 0 12px
    }
    .work_together_social .social_box span {
        display: inline-block
    }
    .right-f.international {
        right: 0;
        animation-delay: 1.7s
    }
    .ideas span,
    .international span,
    .iso span,
    .support span,
    .pricing span,
    .security span {
        font-size: 14px
    }
}

@media (max-width: 960px) {
    .footer-middle-bg .footer-img-hover img {
        width: 100%
    }
}

@media (max-width: 768px) {
    .work_together_social .boxs {
        text-align: center;
        padding: 12px 0
    }
    .work_together_social .social_box:first-child {
        margin: 0 12px 0 0
    }
    .work_together_social .title h2 {
        font-size: 22px
    }
    .international span {
        font-size: 14px
    }
    .iso span {
        font-size: 14px
    }
    .support span {
        font-size: 14px
    }
    .ideas img {
        height: 70px
    }
}

@media only screen and (max-width: 768px) {
    #overlay {
        height: auto
    }
}

@media only screen and (min-width: 769px) {
    .visible-mobile {
        display: none !important
    }
    .visible-tablet {
        display: none !important
    }
    .hidden-desktop {
        display: none !important
    }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
    .visible-mobile {
        display: none !important
    }
    .hidden-tablet {
        display: none !important
    }
    .visible-desktop {
        display: none !important
    }
}

@media only screen and (max-width: 479px) {
    .hidden-mobile {
        display: none !important
    }
    .visible-tablet {
        display: none !important
    }
    .visible-desktop {
        display: none !important
    }
}

.footer-img-hover .blo {
    margin: 0 auto;
    left: 0
}

#scroll-section i.fa.fa-arrow-down {
    background-image: url(#{$cloudfrontpath}/404/arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
    display: inline-block;
    background-size: 55%;
    width: 20px;
    height: 20px;
    margin: 3px 0 0 -1px
}

.bread-crumbs .fa.fa-chevron-right {
    background-image: url(#{$cloudfrontpath}/404/arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(0);
    display: inline-block;
    width: 10px;
    height: 11px;
    margin: 0 2px -1px
}

i.fa.fa-times:after {
    content: "X";
    height: 20px;
    width: 20px;
    right: -23px;
    top: -23px;
    color: #000;
    z-index: 999999;
    font-style: normal;
    font-size: 15px;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px 5px;
    font-weight: 700
}

i.fa.fa-times:before {
    display: none
}

.noscaleportfolia .banner {
    background-color: transparent !important
}

.social_box a:hover span {
    color: #0d99d0
}

#ficon1 path {
    stroke-width: 10
}

#ficon2 path {
    stroke-width: 10
}

#ficon3 path {
    stroke-width: 10
}

#ficon4 path {
    stroke-width: 10
}

.portofolio-hidden #bs-example-navbar-collapse-1 {
    display: none;
    visibility: hidden
}

.portofolio-hidden.sticky #bs-example-navbar-collapse-1 {
    display: block;
    visibility: visible
}

.error-page-bg {
    background: rgba(0,
    0,
    0,
    0) url("#{$cloudfrontpath}/404/banner-2.jpg") no-repeat scroll 0 0;
    padding-bottom: 40px;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
}

#rocket {
    background: rgba(0,
    0,
    0,
    0) url("#{$cloudfrontpath}/404/rocket.png") no-repeat scroll 0 0;
    height: 150px;
    margin: 10px auto 0px;
    position: relative;
    width: 275px;
    top: 110px;
    line-height: 0
}

#rocket img {
    left: 0;
    vertical-align: top;
    bottom: 5%;
    position: absolute
}

.steam1,
.steam2 {
    position: absolute;
    bottom: 78px;
    left: 50px;
    width: 80px;
    height: 80px;
    background: url("#{$cloudfrontpath}/404/steam.png") no-repeat;
    opacity: 0.8
}

.steam2 {
    background-position: left bottom
}

hgroup {
    display: block;
    margin: 0 auto;
    max-width: 1170px;
    width: 100%;
    font-family: 'Century Gothic', Calibri, 'Myriad Pro', Arial, Helvetica, sans-serif;
    text-align: center
}

.error-404.not-found .page-title {
    font-size: 280px;
    font-weight: bold;
    text-shadow: 1px 1px 0 #138ab9, -3px -3px 0 #138ab9, 3px -3px 0 #138ab9, -3px 3px 0 #138ab9, 0px 3px 0 #138ab9, 1px 0px 0 #138ab9, 0px -1px 0 #138ab9, -1px 0px 0 #138ab9, 4px 4px 3px #138ab9;
    color: #00a7e1;
    margin: 0
}

.error404 .bread-crumbs.display-none {
    display: none
}

.cloud-img {
    float: right;
    left: -53%;
    position: relative;
    top: -166px
}

.error-page-bg .page-content {
    margin-top: 20px
}

.cloud-1 {
    left: 0;
    top: 100px;
    z-index: 99
}

.cloud-2 {
    left: 54px;
    top: 50px;
    z-index: 9
}

.cloud-3 {
    left: 109px;
    top: -29px
}

.cloud-1,
.cloud-2,
.cloud-3 {
    position: absolute
}

.page-header {
    border-bottom: 0 none !important;
    margin: 0 !important;
    padding: 0 !important
}

.tag-line-error {
    font-size: 53px;
    color: #138ab9;
    margin: 0;
    padding: 0;
    text-transform: uppercase
}

.right-side-form #inquiry-services,
.right-side-form #inquiry-platform {
    height: 38px;
    margin: 0 0 15px;
    width: 100%
}

.search-404 .img-responsive {
    margin: 0 auto
}

.page-content .tag-line-error {
    margin: 20px 0
}

.opps-text {
    margin: 0 auto;
    width: 67%
}

.opps-text .home {
    float: left
}

.opps-text h1 {
    display: inline-block;
    font-size: 21px;
    text-transform: uppercase;
    width: 33.33%
}

.opps-text .con {
    float: right
}

.rocketFlam {
    animation: flam 0.3s infinite
}

@keyframes flam {
    0% {
        transform: scale(1)
    }
    33% {
        transform: scale(0.9)
    }
    100% {
        transform: scale(1)
    }
}

.cloud-img .cloud-1 {
    width: 105%;
    left: 0;
    height: 105%;
    top: 0;
    -webkit-transform: translate(-100px, 100px);
    transform: translate(-100px, 100px);
    img{
        height: 80px;
    }
}

.cloud-img .cloud-2 {
    width: 108%;
    left: 0;
    height: 108%;
    top: 0;
    -webkit-transform: translate(-50px, 50px);
    transform: translate(-50px, 50px);
    img{
        height: 90px;
    }
}

.cloud-img .cloud-3 {
    width: 110%;
    left: 0;
    height: 110%;
    top: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
    img{
        height: 100px;
    }
}

button.go-back {
    background: #00a7e1;
    border: 1px solid #00a7e1;
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    margin: 30px 0 0
}

@media (max-width: 1259px) {
    .page-content {
        display: inline-block
    }
    .search-404 {
        display: inline-block
    }
    .opps-text {
        width: 95%
    }
}

.our-client {
    background: #f5f5f5;
    padding: 40px 0
}

.our-client h2 {
    color: #00749a;
    font-family: "Poppins-Black";
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 20px;
    position: relative;
    text-transform: uppercase
}

.our-client h2::after {
    background: #464342;
    bottom: -2px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    width: 40px
}

.our-client .item {
    display: table-cell;
    height: 65px;
    vertical-align: middle;
    width: 500px;
    text-align: center
}

.our-client .item img {
    margin: auto;
    max-height: 60px;
    max-width: 140px;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    cursor: pointer;
    width: auto
}

.our-client .item img:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0%);
    -moz--filter: grayscale(0%)
}

.policy-info {
    background: #fff;
    padding: 50px;
    text-align: center
}

.modal-dialog {
    z-index: 9999;
    width: auto;
    display: inline-block
}

.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding: 0 5px
}

.modal-open .modal {
    text-align: center
}

#privacypolicy .modal-dialog,
#termsofuse .modal-dialog {
    width: 70%
}

#privacypolicy .modal-content,
#termsofuse .modal-content {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 35px 50px
}

.pri-section-one.privacy-section {
    text-align: left
}

.pri-section-one.privacy-section {
    display: block;
    height: 85vh;
    text-align: left
}

.first_section.privacy-policy-header {
    height: 0px
}

.privacy-section .sec-head .hed-txt::after {
    display: none
}

.privacy-section .sec-head .hed-txt {
    margin: 0px
}

.pri-section-one p,
.pri-section-one ol {
    color: #949494;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 25px
}

.pri-section-one ol {
    padding-left: 20px;
    list-style-type: lower-alpha
}

.pri-section-one h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 0 0 5px;
    padding-left: 10px;
    position: relative;
    color: #999
}

.pri-section-one h4:before {
    background: #f55247;
    content: "";
    height: 15px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 3px
}

.privacy-list {
    padding: 20px 0
}

.pri-section-one .sec-head {
    margin: 0 0 15px 0
}

.text-bold {
    font-weight: 900;
    float: left;
    width: 2%
}

.modify-rules {
    padding-left: 9px
}

.text-float {
    float: left;
    width: 98%;
    padding: 5px 0 0
}

.animate-box {
    position: absolute;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto
}

.animate-box>span {
    position: absolute;
    display: block;
    height: 31px;
    width: 50px;
    top: 42.9%;
    left: 0
}

.animate-box .helicopter {
    top: 50px;
    animation: planfly 11s infinite linear;
    left: -20px
}

.helicopter span {
    height: 100%;
    width: 100%;
    display: block;
    background: url("#{$cloudfrontpath}/404/sevice-sprite-webcms.png") no-repeat -536px -195px;
    animation: plan 0.3s infinite
}

.animate-box>span,
.animate-box>span {
    top: 42.2%
}

.animate-box .helicopter {
    top: 50px
}

@keyframes left-marquee-new {
    0% {
        background-position: 0 0
    }
    100% {
        background-position: -3840px 0
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .social_box .icons div img {
        border: none
    }
    .sub-page .site-title>img {
        border: none
    }
    .active.skype-online .border-img-ring img {
        border: none
    }
}

@media screen and (max-width: 1680px) and (min-width: 1280px) {
    .blog-center .blog-img .b-text {
        top: 90%;
        transform: translate(-50%, -90%)
    }
}

@media screen and (min-width: 1200px) {
    .lets-work-margin {
        padding-left: 100px
    }
}