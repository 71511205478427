.out-box-bc {
    position: absolute;
    top: 49px;
    width: 309px;
    right: 0;
    left: 0;
}

.first_section {
    &.section {
        background-attachment: fixed;
    }
}

@-webkit-keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%,
    20% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
}

@-webkit-keyframes bounce_ {
    0%,
    100%,
    20%,
    53%,
    80% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@keyframes bounce_ {
    0%,
    100%,
    20%,
    53%,
    80% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    40%,
    43% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }
    70% {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }
    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

.bounce {
    -webkit-animation-name: bounce_;
    animation-name: bounce_;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

.opacity {
    opacity: 0;
    transition: all 0.3s;
}

.opacity_active {
    opacity: 1;
}

#section9 {
    background-attachment: fixed;
}

.lazyload.img-responsive {
    margin: 0 auto;
}

.lazyload-box {
    position: relative;
}

.lazyload-box::before,
.lazyload-box::after {
    border: 3px solid transparent;
    width: 0;
    height: 0;
    box-sizing: inherit;
    content: "";
    position: absolute;
    opacity: 0;
}

.lazyload-box::before {
    top: 0;
    left: 28px;
}

.lazyload-box::after {
    bottom: 0;
    right: 0;
    top: 0;
    left: 28px;
}

.lazyload-box:after {
    border-bottom-color: #f55247;
    border-left-color: #f55247;
}

.lazyload-box:hover {
    color: #f55247;
}

.lazyload-box:hover::before {
    border-top-color: #f55247;
    border-right-color: #f55247;
    -webkit-transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
    transition: width 0.25s ease-out, height 0.25s ease-out 0.25s;
    width: 81%;
    height: 100%;
    opacity: 1;
}

.lazyload-box:hover::after {
    border-bottom-color: #f55247;
    border-left-color: #f55247;
    -webkit-transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
    transition: height 0.25s ease-out, width 0.25s ease-out 0.25s;
    width: 81%;
    height: 100%;
    opacity: 1;
}

.selfi-box {
    overflow: hidden;
    img {
        transform: scale(1);
        -webkit-transform: scale(1);
        -webkit-transition: all 0.3s;
    }
    &:hover {
        img {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
        }
    }
}

.office-add {
    position: relative;
}

.animate-box {
    position: absolute;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.animate-box>span {
    position: absolute;
    display: block;
    height: 30px;
    width: 50px;
    top: 49%;
    left: 0;
}

.cycle {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -754px -192px;
    transform: translateX(-100%);
}

.bike {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -649px -190px;
    transform: translateX(-100%);
}

.car {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -586px -188px;
    transform: translateX(-100%);
}

.office-add.active .cycle {
    animation: cycle 6s 4s linear forwards;
}

.office-add.active .bike {
    animation: bike 6s 2s linear forwards;
}

.office-add.active .car {
    animation: footer-bus 6s 4s linear forwards;
    left: auto;
    right: 28%;
    transform: translateX(-100%);
}

.animate-box .helicopter {
    top: 50px;
    animation: planfly 11s infinite linear;
    left: -20px;
}

.helicopter {
    span {
        height: 100%;
        width: 100%;
        display: block;
        background: url("#{$cloudfrontpath}/animate_icon/helicopter.svg") no-repeat bottom center;
        animation: plan 0.3s infinite;
    }
}

.footer-container {
    position: relative;
}

@keyframes cycle {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: 275px;
        transform: translateX(100%);
    }
}

@keyframes footer-bus {
    0% {
        right: 28%;
        transform: translateX(-100%);
    }
    100% {
        right: 7%;
        transform: translateX(100%);
    }
}

@keyframes planfly {
    0% {
        left: -20px;
    }
    100% {
        left: 103%;
    }
}

@keyframes bike {
    0% {
        left: -50px;
        transform: rotate(15deg);
    }
    100% {
        left: 495px;
        transform: rotate(15deg);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 80%, 0);
        transform: translate3d(0, 80%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 80%, 0);
        transform: translate3d(0, 80%, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

svg#icon1,
svg#icon2,
svg#icon3,
svg#icon4,
svg#icon5 {
    opacity: 0;
    transition: opacity 0.1s linear 0.4s;
    max-width: 100%;
}

.opacity.opacity_active svg#icon1,
.opacity.opacity_active svg#icon2,
.opacity.opacity_active svg#icon3,
.opacity.opacity_active svg#icon4,
.opacity.opacity_active svg#icon5 {
    opacity: 1;
}

svg path {
    stroke-width: 2px;
}

#businessc {
    opacity: 1;
    width: 100%;
}

.lazyload.img-responsive.out-box-bc {
    margin-top: 12px;
    z-index: 88;
}

.hide {
    display: none !important;
}

.out-box-bc.top {
    top: 52px;
}

.out-box-bc.bottom {
    top: 130px;
}

#particles {
    position: relative;
}

#particle-slider {
    position: absolute;
    top: 2px;
    height: 100%;
    width: 100%;
}

.animateHire {
    visibility: hidden;
}

.animated {
    visibility: visible;
    animation-duration: 0.8s;
}

.footer-img1 {
    background-position: 0px -111px;
    filter: grayscale(100%);
    transition: all 0.3s;
}

.footer-img1:hover {
    filter: grayscale(0%);
}

.nl-submit.btn {
    transition: all 0.3s;
    border: 2px solid transparent;
}

.nl-submit.btn:hover {
    background: #fff;
    color: #f55247;
    border: 2px solid #f55247;
}

.eight_section {
    background-attachment: fixed;
}

.infographic-icons {
    position: absolute;
    top: 0;
    opacity: 0;
    .keyben-icon {
        width: 50px;
        position: absolute;
    }
    .bulding.keyben-icon {
        left: 184px;
        top: 225px;
    }
    .quality.keyben-icon {
        left: 76px;
        top: 132px;
    }
    .achievments.keyben-icon {
        left: 299px;
        top: 136px;
    }
    .user-idea.keyben-icon {
        left: 185px;
        top: 415px;
    }
    .group.keyben-icon {
        left: 295px;
        top: 320px;
    }
    .user-setting.keyben-icon {
        top: 318px;
        left: 79px;
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .infographic-icons {
        .user-idea.keyben-icon {
            left: 184px;
            top: 360px;
        }
        .bulding.keyben-icon {
            left: 184px;
            top: 175px;
        }
        .quality.keyben-icon {
            left: 76px;
            top: 82px;
        }
        .achievments.keyben-icon {
            left: 299px;
            top: 87px;
        }
        .group.keyben-icon {
            left: 295px;
            top: 270px;
        }
        .user-setting.keyben-icon {
            top: 268px;
            left: 79px;
        }
    }
}

.img-box {
    &.active {
        .infographic-icons {
            opacity: 1;
        }
    }
}

.dev-imgbox {
    position: relative;
}

.hour-hand {
    content: "";
    top: 2px;
    height: 1px;
    width: 10px;
    position: absolute;
    background-color: #000;
    right: -8px;
    animation: rotates 20s infinite linear;
    transform-origin: center left;
}

.second-hand {
    content: "";
    top: -5px;
    height: 8px;
    width: 1px;
    position: absolute;
    background-color: #ee5a28;
    right: 2px;
    animation: rotates 10s infinite linear;
    transform-origin: 0 7px;
}

.center-dot {
    content: "";
    top: 0;
    height: 5px;
    width: 5px;
    position: absolute;
    background-color: #444;
    right: 0;
    border-radius: 50%;
    z-index: 5;
}

svg#steam-icon {
    position: absolute;
    top: 210px;
    right: 70px;
    width: 40px;
    height: 40px;
    animation: steam 3s ease-out infinite;
}

@keyframes steam {
    0% {
        top: 210px;
        opacity: 0;
        visibility: visible;
    }
    50% {
        top: 200px;
        opacity: 0.95;
    }
    100% {
        top: 190px;
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes rotates {
    100% {
        transform: rotate(360deg);
    }
}


/* office desk animation */

.wow {
    visibility: hidden;
}

.lampEffect {
    animation: lamp 2s 0.8s;
    transform-origin: 20% 60%;
    z-index: 9;
}

.lamp-stands {
    display: block;
}

.slider *,
.dev-imgbox * {
    backface-visibility: visible;
}

@keyframes lamp {
    0% {
        transform: rotate(0deg);
    }
    20% {
        transform: rotate(10deg);
    }
    40% {
        transform: rotate(-8deg);
    }
    60% {
        transform: rotate(6deg);
    }
    80% {
        transform: rotate(-3deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.steam-animation {
    animation: steam linear infinite 4s;
    transform: translateY(0);
}

@keyframes steam {
    0% {
        transform: translateY(0px);
        opacity: 0.2;
    }
    20% {
        transform: translateY(-5px);
        opacity: 0.3;
    }
    40% {
        transform: translateY(-8px);
        opacity: 0.4;
    }
    60% {
        transform: translateY(-12px);
        opacity: 0.6;
    }
    80% {
        transform: translateY(-15px);
        opacity: 0.4;
    }
    100% {
        transform: translateY(-25px);
        opacity: 0;
    }
}

.hover-effect {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid #c1c1c1;
        height: 0%;
        pointer-events: none;
        width: 0%;
        border-left: 0;
        border-bottom: 0;
        transition: width 0.2s 0.2s linear, height 0.2s 0s linear, opacity 0s 0.4s;
        opacity: 0;
    }
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        border: 2px solid #c1c1c1;
        height: 0%;
        pointer-events: none;
        width: 0%;
        border-right: 0;
        border-top: 0;
        transition: height 0.2s 0s linear, width 0.2s 0.2s linear, opacity 0s 0.4s;
        opacity: 0;
    }
    &:hover {
        &:after {
            height: 100%;
            width: 100%;
            transition: width 0.2s 0s linear, height 0.2s 0.2s linear, opacity 0s 0s;
            opacity: 1;
        }
        &:before {
            height: 100%;
            width: 100%;
            transition: width 0.2s 0s linear, height 0.2s 0.2s linear, opacity 0s 0s;
            opacity: 1;
        }
    }
}

.del-values {
    .value-white-dev {
        border: none;
        &:hover {
            transform: none;
            box-shadow: none;
        }
    }
}


/* End office desk animation */

@media (min-width: 1126px) {
    .lazyload-box {
        width: 240px;
        margin: 0px auto;
    }
    .lazyload-box:hover::after,
    .lazyload-box:hover::before {
        width: 100%;
    }
    .lazyload-box::after,
    .lazyload-box::before {
        left: 0px;
        right: 0px;
    }
    .out-box-bc.top {
        top: 57px;
    }
    .out-box-bc.bottom {
        top: 125px;
    }
}

@media (max-width: 1125px) {
    .lazyload-box:hover::after,
    .lazyload-box:hover::before {
        width: 100%;
    }
    .lazyload-box::after,
    .lazyload-box::before {
        left: 0px;
        right: 0px;
    }
}

@media (max-width: 1024px) {
    .infographic-icons {
        .keyben-icon {
            &.user-setting {
                top: 298px;
                left: 72px;
            }
            &.quality {
                top: 128px;
                left: 69px;
            }
            &.achievments {
                left: 275px;
                top: 131px;
            }
            &.group {
                top: 300px;
                left: 270px;
            }
            &.user-idea {
                top: 387px;
                left: 170px;
            }
            &.bulding {
                top: 211px;
                left: 169px;
            }
        }
    }
}

@media (max-width: 991px) {
    .animate-box>span {
        top: 44%;
    }
}