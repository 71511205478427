//@extend-elements
//original selectors
//.ideas span, .international span, .iso span, .support span, .pricing span, .security span
%extend_1 {
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    display: block;
    text-align: center;
    margin-top: 10px;
    font-family: Poppins, sans-serif;
    font-display: swap;
}

//original selectors
//.footer-parallex:before, .footer-parallex:after
%extend_2 {
    content: "";
    display: block;
    clear: both;
}

.swing {
    margin-left: 0;
    right: 18%;
}

.footer_title {
    display: inline-block;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 22px;
    h3 {
        color: #fff;
        font-family: Poppins, sans-serif;
        font-display: swap;
        font-weight: 800;
        font-size: 28px!important;
        margin: 0!important;
    }
    h4 {
        color: #fff;
        font-family: Poppins, sans-serif;
        font-display: swap;
        font-weight: 500;
        font-size: 28px;
        display: inline-table;
    }
}

.new_footer_bg {
    display: inline-block;
    width: 100%;
    margin: 46px 0 48px;
}

.work_together_img {
    text-align: center;
    margin-top: 20px;
    position: relative;
    height: 250px;
}

.work_together_social {
    text-align: center;
    font-size: 0;
    .title {
        width: 100%;
        display: inline-block;
        background: #0d99d0;
        margin: 0;
        h2 {
            font-weight: 500;
            font-size: 26px;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            margin: 8px 0 6px;
            font-family: Poppins, sans-serif;
            font-display: swap;
        }
    }
    .social_box {
        display: inline-block;
        margin: 0 18px;
        span {
            color: #11345a;
            font-weight: 600;
            font-family: Poppins, sans-serif;
            font-display: swap;
            font-size: 14px;
            vertical-align: middle;
            line-height: 33px;
            margin: 0 0 0 10px;
            display: inline-block;
        }
        &:first-child {
            margin: 0 0 0 22px;
        }
        .icons {
            border: 2px solid #0d99cf;
            width: 34px;
            height: 34px;
            display: table;
            line-height: 26px;
            text-align: center;
            float: left;
            div {
                display: table-cell;
                vertical-align: middle;
            }
        }
        a:hover {
            text-decoration: none;
        }
    }
    .boxs {
        border: 2px solid #6ec2e3;
        border-top: none;
        background-color: #fff;
        padding: 22px 0;
        overflow: auto;
        text-align: center;
    }
    .title-footer-together {
        h4 {
            color: #0d99d0;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                width: 30%;
                height: 3px;
                top: 7px;
                left: 25px;
                background-image: radial-gradient(circle at right, #1c95ca 0, rgba(0, 0, 0, 0) 100%);
            }
            &::after {
                content: "";
                position: absolute;
                width: 30%;
                height: 3px;
                top: 7px;
                right: 25px;
                background-image: radial-gradient(circle at left, #1c95ca 0, rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}

.floting-icon {
    animation: bouncing 3s infinite ease-in-out;
}

.certifications-title {
    color: #10355a;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    text-transform: uppercase;
    font-family: Poppins, sans-serif;
    font-display: swap;
    border-right: 1px solid #ccc;
    margin: 0;
}

.footer-middle-bg {
    background: #f6f6f6;
    padding: 37px 0 36px;
    clear: both;
}

.ideas {
    width: 54%;
    position: absolute;
    top: 140px;
    text-align: center;
    animation-delay: 1.3s;
    text-align: center;
    span {
        @extend %extend_1;
    }
}

.international {
    width: 54%;
    position: absolute;
    top: -21px;
    text-align: center;
    text-align: center;
    span {
        @extend %extend_1;
    }
}

.iso {
    width: 54%;
    position: absolute;
    top: 158px;
    text-align: center;
    animation-delay: 1.15s;
    text-align: center;
    span {
        @extend %extend_1;
    }
}

.support {
    width: 54%;
    position: absolute;
    top: 285px;
    text-align: center;
    animation-delay: 2.15s;
    text-align: center;
    span {
        @extend %extend_1;
    }
}

.pricing {
    width: 54%;
    position: absolute;
    top: -68px;
    text-align: center;
    left: -51%;
    span {
        @extend %extend_1;
    }
}

.security {
    width: 54%;
    position: absolute;
    top: -70px;
    text-align: center;
    right: -43%;
    span {
        @extend %extend_1;
    }
}

.blue-bg {
    background: #10355a;
}

.left-f {
    left: 0;
}

.right-f {
    right: 0;
}

.footer {
    clear: both;
}

.scale-page {
    margin-bottom: 594px;
    background: #fff;
}

#padding-animate {
    padding-bottom: 1px;
}

.footer-parallex {
    &:before {
        @extend %extend_2;
    }
    &:after {
        @extend %extend_2;
    }
}

#fooball-inner {
    background-color: #11345a;
    width: 100%;
    position: fixed;
    height: 555px;
    bottom: 0;
    z-index: 2;
}

.footer-body {
    width: 100%;
    #canvas {
        margin: 0 auto;
        width: 100%;
    }
}

.banner {
    z-index: 3;
    background-color: #888 !important;
}

.sub-banner {
    z-index: 3;
    background-color: #888;
}

#wrap {
    position: relative;
    z-index: 3;
}

#content {
    width: 100%;
    position: absolute;
    z-index: 1;
    clear: both;
    p {
        font-size: 1.25rem;
        letter-spacing: .02rem;
        margin-bottom: 1.3rem;
    }
}

@keyframes bouncing {
    0% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate(0, 0);
    }
    65% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate(0, 15px);
    }
    100% {
        //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
        transform: translate(0, 0);
    }
}

.spine {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .animated.wow {
        visibility: hidden !important;
    }
}

.spine2 {
    right: 12%;
    #ficon2 {
        opacity: 0;
    }
    img {
        position: absolute;
        bottom: 164px;
        left: 44.5%;
    }
    &.active #ficon2 {
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 43%;
        opacity: 1;
    }
}

.spine3 {
    left: 22.5%;
    #ficon3 {
        opacity: 0;
    }
    img {
        position: absolute;
        bottom: 45px;
        right: 43%;
    }
    &.active #ficon3 {
        opacity: 1;
        position: absolute;
        bottom: 0;
        right: 40.5%;
        opacity: 1;
    }
}

.spine4 {
    left: 19%;
    #ficon4 {
        opacity: 0;
    }
    img {
        position: absolute;
        bottom: 159px;
        left: 42.5%;
    }
    &.active #ficon4 {
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 40%;
        opacity: 1;
    }
}

.spine1 {
    #ficon1 {
        opacity: 0;
    }
    img {
        position: absolute;
        bottom: 30px;
        left: 31.5%;
    }
    &.active #ficon1 {
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 29.5%;
        opacity: 1;
    }
}

@media(max-width:1680px) {
    .pricing {
        left: -36%;
    }
}

@media(max-width:1440px) {
    .pricing {
        left: -33%;
    }
}

@media(max-width:1024px) {
    .work_together_social {
        .social_box {
            width: 7%;
            margin: 0 5% 10px;
            &:first-child {
                margin: 0 5% 10px;
            }
            span {
                display: none;
            }
        }
        .boxs {
            padding: 12px 0 3px;
        }
        .title-footer-together {
            h4 {
                &::before {
                    left: 0;
                }
                &::after {
                    right: 0;
                }
            }
        }
    }
    .pricing {
        left: 6%;
        top: -100px;
    }
    .security {
        top: -90px;
        right: 0;
    }
    .international {
        top: 20px;
    }
    .spine1 {
        img {
            left: 27.5%;
        }
        &.active #ficon1 {
            left: 25%;
        }
    }
    .spine2 {
        img {
            left: 43.5%;
        }
        &.active #ficon2 {
            left: 41%;
        }
    }
    .spine3 {
        img {
            right: 33%;
        }
        &.active #ficon3 {
            right: 29.5%;
        }
    }
    .spine4 {
        img {
            left: 47.5%;
        }
        &.active #ficon4 {
            left: 43.5%;
        }
    }
    .ideas {
        right: 5%;
        top: 155px;
    }
    .support {
        top: 265px;
    }
    .iso {
        top: 148px;
    }
}

@media(max-width:991px) {
    .floting-icon {
        float: left;
        width: 25%;
        position: relative;
        top: 0;
        min-height: 140px;
    }
    .work_together_img {
        display: none;
        img {
            display: none !important;
        }
    }
    .xs-offset-3 {
        margin: 0;
    }
    .support {
        top: 0;
        top: 0;
    }
    .iso {
        top: auto;
        bottom: 10px;
    }
    .ideas {
        top: 0;
        right: 0;
    }
    .left-f {
        left: 0;
    }
    .swing {
        display: none;
    }
    .work_together_social .social_box {
        width: auto;
        margin: 0 12px;
        span {
            display: inline-block;
        }
    }
    .right-f.international {
        right: 0;
        animation-delay: 1.7s;
    }
    .ideas span,
    .international span,
    .iso span,
    .support span,
    .pricing span,
    .security span {
        font-size: 14px;
    }
}

@media(max-width:960px) {
    .footer-middle-bg .footer-img-hover img {
        width: 100%;
    }
}

@media(max-width:768px) {
    .work_together_social {
        .boxs {
            text-align: center;
            padding: 12px 0;
        }
        .social_box:first-child {
            margin: 0 12px 0 0;
        }
        .title h2 {
            font-size: 22px;
        }
    }
    .international span {
        font-size: 14px;
    }
    .iso span {
        font-size: 14px;
    }
    .support span {
        font-size: 14px;
    }
    .ideas img {
        height: 70px;
    }
}

@media only screen and(max-width:768px) {
    #overlay {
        height: auto;
    }
}

@media only screen and(min-width:769px) {
    .visible-mobile {
        display: none !important;
    }
    .visible-tablet {
        display: none !important;
    }
    .hidden-desktop {
        display: none !important;
    }
}

@media only screen and(min-width:480px) and(max-width:768px) {
    .visible-mobile {
        display: none !important;
    }
    .hidden-tablet {
        display: none !important;
    }
    .visible-desktop {
        display: none !important;
    }
}

@media only screen and(max-width:479px) {
    .hidden-mobile {
        display: none !important;
    }
    .visible-tablet {
        display: none !important;
    }
    .visible-desktop {
        display: none !important;
    }
}

.footer-img-hover .blo {
    margin: 0 auto;
    left: 0;
}

#scroll-section i.fa.fa-arrow-down {
    background-image: url(#{$cloudfrontpath}/footer/arrow.svg);
    background-repeat: no-repeat;
    //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
    transform: rotate(90deg);
    display: inline-block;
    background-size: 55%;
    width: 20px;
    height: 20px;
    margin: 3px 0 0 -1px;
}

.bread-crumbs .fa.fa-chevron-right {
    background-image: url(#{$cloudfrontpath}/common/arrow.svg);
    background-repeat: no-repeat;
    transform: rotate(0);
    display: inline-block;
    width: 10px;
    height: 11px;
    margin: 0 2px -1px;
}

i.fa.fa-times {
    &:after {
        content: "X";
        height: 20px;
        width: 20px;
        right: -23px;
        top: -23px;
        color: #000;
        z-index: 999999;
        font-style: normal;
        font-size: 15px;
        position: absolute;
        background-color: #fff;
        border-radius: 50%;
        padding: 2px 5px;
        font-weight: 700;
    }
    &:before {
        display: none;
    }
}

.noscaleportfolia .banner {
    background-color: transparent !important;
}

.social_box a:hover span {
    color: #0d99d0;
}

#ficon1 path {
    stroke-width: 10;
}

#ficon2 path {
    stroke-width: 10;
}

#ficon3 path {
    stroke-width: 10;
}

#ficon4 path {
    stroke-width: 10;
}

.office-add {
    width: 100%;
    float: left;
    padding: 110px 0 30px;
    background: #1593c5;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    .add-box {
        float: left;
        text-align: center;
        width: 33%;
        position: relative;
        &.head-office {
            &:before {
                background-position: -211px -306px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                top: -69px;
            }
            h3 {
                &:after {
                    background-position: -769px -197px;
                    bottom: 0;
                    height: 23px;
                    right: -15px;
                    width: 25px;
                }
            }
        }
        &.usa-office {
            &:before {
                background-position: -639px -310px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                top: -72px;
            }
            h3 {
                &:after {
                    background-position: -649px -190px;
                    bottom: -4px;
                    height: 35px;
                    right: -16px;
                    width: 40px;
                }
            }
        }
        &.spain-office {
            &:before {
                background-position: -438px -308px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                top: -69px;
                width: 202px;
            }
            h3 {
                &:after {
                    background-position: -536px -195px;
                    height: 23px;
                    left: -50px;
                    top: -75px;
                    width: 40px;
                }
            }
        }
        &.mauritius-office {
            &:before {
                background-position: -1090px -308px;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
                top: -69px;
                width: 200px;
            }
        }
        h3 {
            border-bottom: 3px solid #003040;
            color: #fff;
            font-size: 24px;
            line-height: 24px;
            margin: 0;
            text-transform: uppercase;
            position: relative;
        }
        p {
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            margin: 10px 0 0;
            padding: 0 8px;
            text-transform: none;
        }
    }
}

.office-add {
    .add-box {
        &:before {
            background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
            background-repeat: no-repeat;
            content: "";
            position: absolute;
            width: 206px;
            height: 100px;
        }
        h3 {
            &:after {
                background-repeat: no-repeat;
                position: absolute;
                content: "";
                background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
            }
        }
    }
}

.footer-top {
    background-color: #8dbdd8;
    color: #10355A;
    padding: 20px 0px 9px;
    width: 100%;
    float: left;
    border: none;
}

.top-right-footer {
    font-family: "Poppins", sans-serif;
    font-display: swap;
    text-align: right;
}

.top-left {
    >ul {
        display: table;
        margin-bottom: 0;
        padding-left: 0;
    }
}

.top-left {
    li {
        &:first-child {
            padding-left: 0;
        }
    }
}

.top-left {
    li {
        &:before {
            content: "";
            height: 15px;
            left: 0;
            position: absolute;
            top: 2px;
            width: 1px;
            background-color: #10355A !important;
        }
    }
}

.top-left {
    li {
        &:first-child {
            &:before {
                display: none;
            }
        }
    }
}

.footer-top {
    .row {
        .top-left {
            a {
                color: #10355a;
                font-weight: 500;
                font-size: 12px;
            }
        }
    }
}

.top-left {
    li {
        float: left;
        list-style: outside none none;
        padding: 0 9px;
        position: relative;
        font-family: "Poppins", sans-serif;
        font-display: swap;
        font-size: 86.5%;
    }
}

.top-right-footer {
    font-family: "Poppins", sans-serif;
    font-display: swap;
    text-align: right;
}

.top-right-footer {
    >ul {
        float: right;
        margin-bottom: 0;
        padding-left: 8px;
    }
    li {
        float: left;
        list-style: none;
        text-align: center;
    }
}

.bottom-left li:last-child {
    margin-right: 5px;
}

.top-right-footer {
    ul {
        span {
            float: left;
            font-size: 87.5%;
        }
    }
    li {
        i {
            color: #fff;
            font-size: 20px;
            background-size: 268px; 
            &:hover {
                color: #00a6df;
            }
        }
    }
}

.footer-top-middle {
    background: #000;
    color: #fff;
    padding: 25px 0;
}

.middle-left {
    font-family: "Poppins", sans-serif;
    font-display: swap;
    font-size: 18px;
    padding-top: 25px;
    position: relative;
    text-transform: uppercase;
    &::before {
        background: #10355a;
        content: "";
        height: 75px;
        left: auto;
        position: absolute;
        right: 5%;
        top: 20%;
        width: 1px;
    }
}

.footer-top-middle {
    .footer-images {
        width: 100%;
        position: relative;
        display: inline-block;
        text-align: right;
    }
}

.middle-right {
    float: right;
}

.footer-img1 {
    display: inline-block;
    min-height: 96px;
    overflow: hidden;
    width: 100%;
    position: relative;
    text-align: right;
    img {
        position: absolute;
        height: auto;
        right: 0;
        top: -105px;
    }
    &:hover {
        img {
            top: 0px;
        }
    }
}

.tab-follow-link {
    &.top-right-footer {
        display: none;
        background: #313131;
        color: #ffffff;
        padding: 8px;
        text-align: center;
        width: 100%;
        float: left;
    }
}

.footer-bottom {
    background: #c3c3c3;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-display: swap;
    font-size: 14px;
    padding: 8px 0;
    text-align: center;
}

.bottom-left .row>ul {
    padding-left: 0;
    margin: 0;
}

.bottom-left {
    position: relative;
}

.bottom-left:after {
    content: "";
    height: 19px;
    right: 0px;
    position: absolute;
    top: 2px;
    width: 1px;
    background-color: #10355a;
}

.bottom-left {
    li {
        display: inline-block;
        list-style: none;
        padding: 0 5px;
    }
}

.bottom-right {
    color: #000;
    font-size: 12px;
    padding-right: 0;
    padding-top: 4px;
    text-align: center;
    z-index: 99;
}

body.active {
    .bottom-left-logo1 {
        background-image: url("#{$cloudfrontpath}/home/sevice-sprite-microsoft.png");
        background-size: 1121px;
        background-position: -577px -1px;
        display: block;
        height: 30px;
        width: 20px;
    }
    .bottom-left-logo2 {
        background-image: url("#{$cloudfrontpath}/home/sevice-sprite-microsoft.png");
        background-position: -808px -3px;
        display: block;
        height: 26px;
        width: 63px;
    }
    .bottom-left-logo3 {
        background-image: url("#{$cloudfrontpath}/home/sevice-sprite-microsoft.png");
        background-position: -890px 0px;
        display: block;
        height: 28px;
        width: 64px;
    }
    .bottom-left-logo4 {
        background-image: url("#{$cloudfrontpath}/home/sevice-sprite-microsoft.png");
        background-position: -970px 0px;
        display: block;
        height: 28px;
        width: 64px;
    }
    .bottom-left-logo5 {
        background-image: url("#{$cloudfrontpath}/home/sevice-sprite-microsoft.png");
        background-position: -1053px 0px;
        display: block;
        height: 28px;
        width: 43px;
    }
    .bottom-left-logo6 {
        background-image: url("#{$cloudfrontpath}/home/designrush-logo.png");
        display: block;
        height: 26px;
        width: 55px;
        margin: 0 0 0 10px;
    }
}

.bottom-right>a {
    color: #000;
    text-decoration: none;
}

.bottom-right>a:hover {
    color: #1593c5;
    text-decoration: underline;
}

.our-client {
    background: #f5f5f5;
    padding: 40px 0;
    h2 {
        color: #00749a;
        font-family: "Poppins-Black";
        font-display: swap;
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 20px;
        position: relative;
        text-transform: uppercase;
        &::after {
            background: #464342;
            bottom: -2px;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 40px;
        }
    }
    .item {
        display: table-cell;
        height: 65px;
        vertical-align: middle;
        width: 500px;
        text-align: center;
        img {
            margin: auto;
            max-height: 60px;
            max-width: 140px;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            cursor: pointer;
            width: auto;
            &:hover {
                filter: grayscale(0);
                -webkit-filter: grayscale(0%);
                -moz--filter: grayscale(0%);
            }
        }
    }
}

.policy-info {
    background: #fff;
    padding: 50px;
    text-align: center;
}

.modal-dialog {
    z-index: 9999;
    width: auto;
    display: inline-block;
}

.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    padding: 0 5px;
}

.modal-open .modal {
    text-align: center
}

#privacypolicy .modal-dialog,
#termsofuse .modal-dialog {
    width: 70%;
}

#privacypolicy .modal-content,
#termsofuse .modal-content {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 35px 50px;
}

.pri-section-one.privacy-section {
    text-align: left;
}

.pri-section-one.privacy-section {
    display: block;
    height: 85vh;
    text-align: left;
}

.first_section.privacy-policy-header {
    height: 0px;
}

.privacy-section .sec-head .hed-txt::after {
    display: none;
}

.privacy-section .sec-head .hed-txt {
    margin: 0px;
}

.pri-section-one p,
.pri-section-one ol {
    color: #949494;
    font-family: 'Poppins', sans-serif;
    font-display: swap;
    font-weight: 300;
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 25px;
}

.pri-section-one ol {
    padding-left: 20px;
    list-style-type: lower-alpha;
}

.pri-section-one h4 {
    font-family: 'Poppins', sans-serif;
    font-display: swap;
    font-weight: 600;
    margin: 0 0 5px;
    padding-left: 10px;
    position: relative;
    color: #999;
    &:before {
        background: #f55247;
        content: "";
        height: 15px;
        left: 0;
        position: absolute;
        top: 2px;
        width: 3px;
    }
}

.privacy-list {
    padding: 20px 0;
}

.pri-section-one .sec-head {
    margin: 0 0 15px 0;
}

.text-bold {
    font-weight: 900;
    float: left;
    width: 2%;
}

.modify-rules {
    padding-left: 9px;
}

.text-float {
    float: left;
    width: 98%;
    padding: 5px 0 0;
}

.footer-top-middle {
    float: left;
    width: 100%;
}

.middle-left::before {
    display: none
}

.middle-left {
    border-right: 1px solid #1aa8e1;
    margin: 14px 0;
    padding: 10px 0;
    display: inline-block;
    text-align: left;
}

.footer-bottom {
    // float: left;
    width: 100%;
}

.bottom-left ul {
    float: left;
    width: 100%;
    margin: 0;
    text-align: right;
    padding: 0;
}

.bottom-left li {
    display: inline-block;
    width: auto;
}

.bottom-right {
    text-align: left;
    margin: 0;
    padding: 8px 0 0;
    font-size: 14px;
    font-weight: 500;
}

.footer-img-hover {
    position: relative;
}

.footer-img-hover {
    .blo {
        position: absolute;
        right: 0;
        top: 0px;
        display: none !important;
    }
    img {
        width: 100%;
    }
    &:hover {
        .blo {
            display: block !important;
        }
    }
}
body.active {
    .footer-img {
        #marquee {
            background: url(#{$cloudfrontpath}/home/certificate-award.png) 0px 0px repeat;
        }
        #marquee-proudly {
            background: url(#{$cloudfrontpath}/home/proudly-serving.png) 0px 0px repeat;
        }
    }
}

.footer-img {
    #marquee,
    #marquee-proudly {
        position: relative;
        width: 100%;
        animation: left-marquee-new 50s linear 1s infinite normal;
        height: 68px;
    }
}


/*======== Footer animation ======*/

.office-add {
    position: relative;
}

.animate-box {
    position: absolute;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.animate-box>span {
    position: absolute;
    display: block;
    height: 31px;
    width: 50px;
    top: 42.9%;
    left: 0;
}

.office-add .add-box h3:after,
.office-add .add-box h3:before {
    display: none;
}

.cycle {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -754px -192px;
    transform: translateX(-100%);
}

.bike {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -649px -190px;
    transform: translateX(-100%);
}

.car {
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -586px -188px;
    transform: translateX(-100%);
}

.office-add.active .cycle {
    animation: cycle 6s 4s linear forwards;
}

.office-add.active .bike {
    animation: bike 6s 2s linear forwards;
}

.office-add.active .car {
    animation: footer-bus 6s 4s linear forwards;
    left: auto;
    right: 36%;
    transform: translateX(-100%);
}

.animate-box .helicopter {
    top: 50px;
    animation: planfly 11s infinite linear;
    left: -20px
}

.helicopter span {
    height: 100%;
    width: 100%;
    display: block;
    background: url("#{$cloudfrontpath}/common/sevice-sprite-webcms.png") no-repeat -536px -195px;
    animation: plan 0.3s infinite;
}

.animate-box>span,
.animate-box>span {
    top: 42.2%;
}

.animate-box .helicopter {
    top: 50px;
}

.footer-container {
    position: relative;
}

@keyframes cycle {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: 28%;
        transform: translateX(100%)
    }
}

@keyframes footer-bus {
    0% {
        right: 35%;
        transform: translateX(-100%);
    }
    100% {
        right: 5%;
        transform: translateX(100%)
    }
}

@keyframes planfly {
    0% {
        left: -20px;
    }
    100% {
        left: 103%;
    }
}

@keyframes bike {
    0% {
        left: -50px;
        transform: rotate(15deg)
    }
    100% {
        left: 63%;
        transform: rotate(15deg)
    }
}

.top-left {
    padding-left: 0px;
}

.top-right-footer ul li a {
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

body {
    &.active {
        .top-right-footer {
            ul {
                li {
                    a {
                        margin: -5px 5px 0px 0px;
                        i {
                            &.fa-facebook {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: 0px -30px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: 0px 1px;
                                }
                            }
                            &.fa-linkedin {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -34px -30px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -34px 1px;
                                }
                            }
                            &.fa-twitter {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -67px -30px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -67px 1px;
                                }
                            }
                            &.fa-google-plus {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -101px -31px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -101px 1px;
                                }
                            }
                            &.fa-pinterest {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -135px -31px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -135px 1px;
                                }
                            }
                            &.fa-instagram {
                                background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -169px -31px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -169px 1px;
                                }
                            }
                            &.fa-dribble {
                                background-image: url(#{$cloudfrontpath}/home/social-media-viitorcloud.svg);
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -204px -31px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -204px 1px;
                                }
                            }
                            &.fa-behance {
                                background-image: url(#{$cloudfrontpath}/home/social-media-viitorcloud.svg);
                                width: 30px;
                                display: block;
                                background-repeat: no-repeat;
                                height: 30px;
                                background-position: -239px -31px;
                                &:hover {
                                    background-image: url('#{$cloudfrontpath}/home/social-media-viitorcloud.svg');
                                    background-position: -239px 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#return-to-top {
    z-index: 999;
    bottom: 75px;
}

#return-to-top {
    i.fa.fa-arrow-up {
        background-image: url(#{$cloudfrontpath}/common/arrow.svg);
        background-repeat: no-repeat;
        transform: rotate(-90deg);
        display: inline-block;
        background-size: 55%;
        width: 20px;
        height: 20px;
        margin: -4px 0 0 -1px;
    }
}

@keyframes left-marquee-new {
    0% {
        bacgkround-position: 0 0;
    }
    100% {
        background-position: -3840px 0;
    }
}

.footer-middle-bg {
    border-top: 4px solid #EAEAEA;
}

.mylivechat_collapsed {
    right: 90px !important;
    left: auto !important;
}

.skype-online.active {
    .border-img-ring {
        position: relative;
        height: 25px;
        width: 25px;
        top: auto;
        bottom: 10px;
        right: 8px;
        position: relative;
        height: 53px;
        width: 53px;
        top: auto;
        bottom: 24px;
        right: 20px;
        border-radius: 50%;
        background-color: #fff;
        border: 3px solid #34a6de;
    }
}

.skype-online.active {
    transform: translateX(0%);
    transition: all 0.4s;
}

.skype-online {
    transform: translateX(557%);
    transition: all 0.4s;
    display: block;
    position: fixed;
    width: auto;
    bottom: 150px;
    right: 0;
    z-index: 99998;
    height: 24px;
}

.skype-online.active {
    .border-img-ring {
        img {
            padding: 0;
            margin: 13px;
            animation: 1s skype linear infinite alternate;
            -moz-animation: 1s skype linear infinite alternate;
        }
    }
}

.ring {
    position: absolute;
    animation: 2s ringing 1s linear infinite;
    -webkit-animation: 2s ringing linear infinite;
    -o-animation: 2s ringing 1s linear infinite;
    top: 10%;
    transform: translateY(-50%) translateX(-50%);
    left: 10%;
    background-color: #9cdaf8;
    width: 1em;
    height: 1em;
    border-radius: 50%;
}

@keyframes ringing {
    0% {
        box-shadow: 0 0 0 0 rgba(#9cdaf8, 0.3), 0 0 0 1em rgba(#9cdaf8, 0.3), 0 0 0 2em rgba(#9cdaf8, 0.3), 0 0 0 3em rgba(#9cdaf8, 0.3);
    }
    100% {
        box-shadow: 0 0 0 1em rgba(#9cdaf8, 0.3), 0 0 0 2em rgba(#9cdaf8, 0.3), 0 0 0 3em rgba(#9cdaf8, 0.3), 0 0 0 4em rgba(#9cdaf8, 0);
    }
}

@keyframes skype {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .social_box {
        .icons {
            div {
                img {
                    border: none;
                }
            }
        }
    }
    .sub-page {
        .site-title {
            >img {
                border: none;
            }
        }
    }
    .active.skype-online {
        .border-img-ring {
            img {
                border: none;
            }
        }
    }
}


/*==========================1366===================================*/

@media only screen and (max-width: 1366px) {
    .middle-left {
        border-right: 2px solid #1aa8e1;
    }
    .footer-top-middle {
        >.padding-lft-rght {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
}


/*==========================1280===================================*/

@media screen and (max-width:1680px) and (min-width: 1280px) {
    .blog-center {
        .blog-img {
            .b-text {
                top: 90%;
                transform: translate(-50%, -90%);
            }
        }
    }
}

@media only screen and (max-width: 1259px) {
    .office-add {
        .add-box {
            h3 {
                font-size: 22px;
            }
            p {
                font-size: 14px;
            }
        }
    }
    .footer-top {
        padding-left: 15px;
        padding-right: 15px;
    }
    .col-lg-10.col-md-12.col-sm-12.middle-right {
        padding: 0px;
    }
}


/*==========================1200===================================*/

@media only screen and (max-width: 1200px) {
    .office-add {
        .add-box {
            h3 {
                font-size: 20px;
            }
            p {
                font-size: 13px;
            }
        }
    }
    .footer-img1 {
        img {
            width: 900px;
            height: 186px;
        }
    }
    .footer-img1 img {
        top: -98px;
    }
    .footer-img1 {
        min-height: 90px;
    }
    .middle-left {
        margin: 12px 0;
    }
    .col-lg-2.col-md-5.col-sm-5.middle-left {
        >br {
            display: none;
        }
    }
    .footer-img1 {
        img {
            right: auto;
            left: 0;
            min-height: 96px;
        }
    }
}

@media only screen and (max-width: 1025px) {
    .footer-bottom {
        padding: 10px;
    }
}


/*==========================1024===================================*/

@media only screen and (max-width: 1024px) {
    .middle-left {
        margin: 4px 0;
    }
    .office-add {
        .add-box {
            h3 {
                font-size: 18px;
            }
            p {
                font-size: 12px;
            }
        }
    }
    .certifications-title {
        font-size: 17px;
    }
    @keyframes cycle {
        0% {
            left: 0;
        }
        100% {
            left: 25%;
        }
    }
    @keyframes bike {
        0% {
            left: -50px;
            transform: rotate(15deg)
        }
        100% {
            left: 47%;
            transform: rotate(15deg)
        }
    }
    .middle-left {
        padding: 0;
        br {
            display: none;
        }
    }
    .top-left {
        width: 100%;
        margin: 5px 0;
        padding: 0 10px;
        ul {
            margin: 0 auto;
        }
        li {
            float: none;
            display: inline-block;
        }
    }
    .top-right-footer {
        width: 100%;
        margin: 0;
        text-align: center;
    }
    .top-right-footer {
        ul {
            float: none;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            margin-right: 20px;
        }
    }
    .top-right-footer {
        li {
            float: none;
            display: inline-block;
        }
    }
    .footer-top {
        padding: 0px 10px;
    }
    .bottom-left:after {
        display: none;
    }
    body.active {
        .top-right-footer {
            ul {
                li {
                    a {
                        margin: -2px 5px 0px 0px;
                    }
                }
            }
        }
    }
}


/*==========================1023===================================*/

@media only screen and (max-width: 1023px) {
    .office-add {
        margin-top: 0;
        overflow-x: scroll;
    }
    @keyframes cycle {
        0% {
            left: 0;
        }
        100% {
            left: 100px;
        }
    }
    @keyframes bike {
        0% {
            left: -50px;
            transform: rotate(15deg)
        }
        100% {
            left: 330px;
            transform: rotate(15deg)
        }
    }
    .footer-bottom {
        padding: 40px 0;
    }
    .footer-top-middle .middle-right {
        float: inherit;
    }
}


/*==========================980===================================*/

@media only screen and (max-width: 980px) {
    .top-left {
        text-align: center;
        padding-bottom: 5px;
    }
    .top-left>ul {
        display: inline-block;
    }
    .top-right-footer {
        text-align: center;
    }
    .top-right-footer>ul {
        float: none;
        display: inline-flex;
        vertical-align: middle;
        text-align: center;
    }
    .footer-top-middle br {
        display: none;
    }
    .middle-left {
        padding: 0;
    }
    .col-md-4.top-right-footer {
        display: none;
    }
    .footer-top {
        padding: 0px 10px;
    }
    .footer-top .top-left {
        padding: 5px 0 0 0;
        text-align: center;
    }
    .tab-follow-link.top-right-footer {
        display: block;
    }
    .bottom-right {
        font-size: 13px;
    }
    .hlp-box a {
        width: 280px;
    }
    .middle-left {
        margin: 10px 0;
    }
    .footer-bottom {
        padding: 8px 0 0;
    }
}

.virtual-tour .st0 {
    fill: #FFFFFF;
}

.virtual-tour .st1 {
    fill: #00AAFF;
}


/*=========================900===============================*/

@media only screen and (max-width: 900px) {
    .footer-img1 img {
        top: -70px;
    }
    .footer-img1 {
        min-height: 67px;
    }
    .footer-img1:hover img {
        top: 1px;
    }
    .footer-img1 img {
        max-height: 135px;
        width: auto;
    }
}


/*=========================768===============================*/

@media only screen and (max-width: 768px) {
    .middle-left {
        padding: 0;
    }
}

// new_footer css start
.innerpage-footer,
.current-openings-parent{
    font-family: "Poppins", sans-serif;
    font-display: swap;
    clear: both;
    .d-flex {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }
    .justify-content-between {
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }
    .align-item-center {
        -webkit-align-items: center;
        align-items: center;
    }
    .flex-wrap {
        flex-wrap: wrap;
    }
    .ml-auto {
        margin-left: auto;
    }
    .footer-blueline {
        background: #5eb5e7;
        padding: 20px 0;
        h4 {
            color: #00245e;
            font-size: 24px;
        }
        .btn-defualt {
            -webkit-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
            -moz-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
            -ms-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
            -o-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
            transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
            padding: 20px 50px;
            font-size: 18px;
            color: #000;
            font-weight: 500;
            border-radius: 0px;
            box-shadow: #000 0 0px 0px 2px inset;
            text-transform: uppercase;
            background: transparent;
            border: 0;
            &:hover {
                color: #000;
                box-shadow: #fff 0 0px 0px 40px inset;
            }
        }
    }
    .main-footer {
        background-image: url(#{$cloudfrontpath}/footer/send-pattern.svg);
        background-repeat: no-repeat;
        background-position: right 70%;
        padding: 60px 0px;
        background-size: 10%;
        overflow: hidden;
        .footer-form-title {
            text-transform: uppercase;
            font-size: 21px;
            margin-bottom: 30px;
            font-weight: 600;
            display: block;
            margin-top: 0;
        }
        p {
            font-size: 15px;
            color: #878787;
            font-weight: 400;
        }
        ul {
            padding-left: 0;
            li {
                position: relative;
                padding-left: 40px;
                list-style: none;
                padding-bottom: 10px;
                font-size: 15px;
                color: #878787;
                &::before {
                    content: "";
                    position: absolute;
                    background-image: url(#{$cloudfrontpath}/services/bullet-point.svg);
                    background-repeat: no-repeat;
                    width: 22px;
                    height: 24px;
                    background-position: 0 0;
                    left: 0;
                }
            }
        }
        .socialicons {
            .social-field {
                position: absolute;
                &.email-icon {
                    top: 60px;
                    right: 100px;
                }
                &.support-icon {
                    top: 140px;
                    right: 60px;
                }
                &.skype-icon {
                    bottom: 80px;
                    right: 170px;
                }
                &.connectwith-icon {
                    bottom: 0px;
                    right: 122px;
                }
                .social-bg {
                    background-color: #070708;
                    border-radius: 30px;
                    border: 6px solid #89b6d8;
                    height: 60px;
                    width: 60px;
                    padding: 10px;
                    img {
                        height: 30px;
                        width: 100%;
                    }
                }
                h2 {
                    color: #0587c5;
                    font-size: 18px;
                    font-weight: 600;
                    margin-top: 0px;
                }
                h3 {
                    font-size: 15px;
                    color: #878787;
                    font-weight: 400;
                    margin-bottom: 0px;
                    text-transform: unset;
                    margin-top: 0;
                }
                .content-icon {
                    padding-left: 10px;
                }
            }
        }
        .footer-form {
            margin-top: 20px;
            input,
            select {
                background: #f7f7f7;
                // border: 0px;
                border-color: #e6e6e6;
                box-shadow: none;
                height: 36px;
                border-radius: 2px;
                font-weight: 400;
            }
            .btn-blue {
                -webkit-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
                -moz-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
                -ms-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
                -o-transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
                transition: all 200ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
                padding: 12px 20px;
                border-radius: 10px;
                text-align: center;
                font-size: 18px;
                color: #fff;
                font-weight: 500;
                box-shadow: #0490ce 0 0px 0px 2px inset;
                text-transform: uppercase;
                background: #0490ce;
                margin-top: 15px;
                border: 2px solid;
                display: inline-block;
                &:hover {
                    color: #0490ce;
                    box-shadow: #fff 0 0px 0px 40px inset;
                }
            }
            .req-form{
                float: right;
            }
        }
        // footer animation css start
        #rocket {
            position: absolute;
            width: 80px;
            left: 50%;
            margin-left: -40px;
            top: 100px;
            z-index: -2;
        }
        .artboard {
            position: relative;
            width: 300px;
            height: 300px;
            border-radius: 50%;
            border: 6px solid #89b6d8;
            margin: 0 auto;
            overflow: hidden;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
            backface-visibility: none;
            background: radial-gradient(circle at bottom, #25577e 0%, #070708 60%);
            display: inline-block;
        }
        .star {
            position: absolute;
            width: 10px;
            height: 10px;
            top: 37%;
            left: 31%;
        }
        .star::before,
        .star::after {
            content: '';
            position: absolute;
            width: 3px;
            height: 10px;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.15);
            animation: sparkle 1s ease-in-out infinite alternate;
        }
        .star::after {
            transform: translate(-50%, -50%) rotate(90deg);
        }
        .star::before {
            transform: translate(-50%, -50%);
        }
        .star:nth-child(2) {
            top: 20%;
            left: 23%;
            transform: scale(1.4);
        }
        .star:nth-child(3) {
            top: 28%;
            left: 64%;
            transform: scale(0.7);
        }
        @keyframes sparkle {
            from {
                height: 10px;
            }
            to {
                height: 3px;
            }
        }
        .smoke {
            position: absolute;
            width: 400%;
            height: 400%;
            bottom: -250%;
            left: 50%;
            background-color: #0490ce;
            transform: translateX(-50%) scale(0.25);
        }
        .smoke-bubble {
            position: absolute;
            background-color: #0490ce;
            border-radius: 50%;
            animation: smoke infinite 7s alternate ease-in-out;
            &:nth-child(1) {
                left: 1%;
                top: -22%;
                width: 20%;
                height: 20%;
                animation-duration: 5s;
            }
            &:nth-child(2) {
                right: 1%;
                top: -22%;
                width: 20%;
                height: 20%;
                animation-duration: 5s;
            }
            &:nth-child(3) {
                left: -6%;
                top: -28%;
                width: 15%;
                height: 15%;
                animation-duration: 3s;
            }
            &:nth-child(4) {
                right: -6%;
                top: -28%;
                width: 15%;
                height: 15%;
                animation-duration: 3s;
            }
            &:nth-child(5) {
                left: 12%;
                top: -11%;
                width: 20%;
                height: 20%;
                animation-duration: 6s;
            }
            &:nth-child(6) {
                right: 12%;
                top: -11%;
                width: 20%;
                height: 20%;
                animation-duration: 6s;
            }
            &:nth-child(7) {
                left: 28%;
                top: -7%;
                width: 14%;
                height: 14%;
                animation-duration: 3s;
            }
            &:nth-child(8) {
                right: 28%;
                top: -7%;
                width: 14%;
                height: 14%;
                animation-duration: 3s;
            }
            &:nth-child(9) {
                left: 35%;
                top: -6%;
                width: 19%;
                height: 19%;
                animation-duration: 5s;
            }
            &:nth-child(10) {
                right: 35%;
                top: -6%;
                width: 19%;
                height: 19%;
                animation-duration: 5s;
            }
            &:nth-child(11) {
                left: -9%;
                top: -19%;
                width: 30%;
                height: 30%;
                animation: none;
            }
            &:nth-child(12) {
                right: -9%;
                top: -19%;
                width: 30%;
                height: 30%;
                animation: none;
            }
        }
        @keyframes smoke {
            0% {
                transform: scale(1.2);
            }
            50% {
                transform: scale(0.8);
            }
            100% {
                transform: scale(1.2);
            }
        }
        .fire {
            top: 67%;
            z-index: -4;
            position: absolute;
            left: 50%;
            margin-left: -10px;
            width: 20px;
            height: 20px;
            border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
            background-color: #0087a8;
            transform: rotate(135deg);
            margin-top: 20px;
            animation: flicker infinite 1s alternate ease-in-out;
            &:after {
                content: "";
                top: 5px;
                position: absolute;
                width: 15px;
                height: 15px;
                border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
                background-color: #dafffe;
            }
        }
        @keyframes flicker {
            0% {
                transform: rotate(135deg) scale(0.8);
                box-shadow: 0 0 17px 10px rgba(165, 238, 224, 0.5);
            }
            25% {
                box-shadow: 0 0 17px 5px rgba(165, 238, 224, 0.5);
            }
            50% {
                box-shadow: 0 0 17px 7px rgba(165, 238, 224, 0.5);
            }
            75% {
                box-shadow: 0 0 17px 5px rgba(165, 238, 224, 0.5);
            }
            100% {
                box-shadow: 0 0 17px 10px rgba(165, 238, 224, 0.5);
            }
        }
        .takeoff {
            z-index: -4;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 7%;
            height: 100%;
            top: 67%;
            background-image: linear-gradient(to right, #0490ce 0%, #dafffe 25%, #0087a8 50%, #dafffe 75%, #0490ce 100%);
        }
        .shake {
            animation: shake 0.2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) alternate;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
        @keyframes shake {
            10%,
            90% {
                transform: translate3d(-1px, 0, 0);
            }
            20%,
            80% {
                transform: translate3d(-1px, 0, 0);
            }
            30%,
            50%,
            70% {
                transform: translate3d(-2px, 0, 0);
            }
            40%,
            60% {
                transform: translate3d(2px, 0, 0);
            }
        }
        .shootingstar {
            position: absolute;
            width: 3px;
            height: 20px;
            border-radius: 50%;
            box-shadow: 0 0 6px 3px rgba(255, 255, 255, 0.15);
            background-color: #fff;
            z-index: -2;
            animation: shooting 10s infinite ease;
            &:nth-child(1) {
                left: 4px;
                top: 225px;
                animation-delay: calc(-400s / 1);
            }
            &:nth-child(2) {
                left: 98px;
                top: 294px;
                animation-delay: calc(-400s / 2);
            }
            &:nth-child(3) {
                left: 9px;
                top: 207px;
                animation-delay: calc(-400s / 3);
            }
            &:nth-child(4) {
                left: 115px;
                top: 66px;
                animation-delay: calc(-400s / 4);
            }
            &:nth-child(5) {
                left: 197px;
                top: 274px;
                animation-delay: calc(-400s / 5);
            }
            &:nth-child(6) {
                left: 62px;
                top: 48px;
                animation-delay: calc(-400s / 6);
            }
            &:nth-child(7) {
                left: 99px;
                top: 108px;
                animation-delay: calc(-400s / 7);
            }
            &:nth-child(8) {
                left: 95px;
                top: 247px;
                animation-delay: calc(-400s / 8);
            }
            &:nth-child(9) {
                left: 281px;
                top: 13px;
                animation-delay: calc(-400s / 9);
            }
            &:nth-child(10) {
                left: 216px;
                top: 158px;
                animation-delay: calc(-400s / 10);
            }
            &:nth-child(11) {
                left: 49px;
                top: 209px;
                animation-delay: calc(-400s / 11);
            }
            &:nth-child(12) {
                left: 245px;
                top: 87px;
                animation-delay: calc(-400s / 12);
            }
            &:nth-child(13) {
                left: 203px;
                top: 65px;
                animation-delay: calc(-400s / 13);
            }
            &:nth-child(14) {
                left: 106px;
                top: 24px;
                animation-delay: calc(-400s / 14);
            }
            &:nth-child(15) {
                left: 257px;
                top: 286px;
                animation-delay: calc(-400s / 15);
            }
            &:nth-child(16) {
                left: 129px;
                top: 58px;
                animation-delay: calc(-400s / 16);
            }
            &:nth-child(17) {
                left: 221px;
                top: 94px;
                animation-delay: calc(-400s / 17);
            }
            &:nth-child(18) {
                left: 139px;
                top: 58px;
                animation-delay: calc(-400s / 18);
            }
            &:nth-child(19) {
                left: 77px;
                top: 286px;
                animation-delay: calc(-400s / 19);
            }
            &:nth-child(20) {
                left: 222px;
                top: 212px;
                animation-delay: calc(-400s / 20);
            }
            &:nth-child(21) {
                left: 197px;
                top: 153px;
                animation-delay: calc(-400s / 21);
            }
            &:nth-child(22) {
                left: 96px;
                top: 129px;
                animation-delay: calc(-400s / 22);
            }
            &:nth-child(23) {
                left: 133px;
                top: 115px;
                animation-delay: calc(-400s / 23);
            }
            &:nth-child(24) {
                left: 232px;
                top: 23px;
                animation-delay: calc(-400s / 24);
            }
            &:nth-child(25) {
                left: 21px;
                top: 91px;
                animation-delay: calc(-400s / 25);
            }
            &:nth-child(26) {
                left: 75px;
                top: 97px;
                animation-delay: calc(-400s / 26);
            }
            &:nth-child(27) {
                left: 149px;
                top: 288px;
                animation-delay: calc(-400s / 27);
            }
            &:nth-child(28) {
                left: 84px;
                top: 133px;
                animation-delay: calc(-400s / 28);
            }
            &:nth-child(29) {
                left: 125px;
                top: 71px;
                animation-delay: calc(-400s / 29);
            }
            &:nth-child(30) {
                left: 40px;
                top: 258px;
                animation-delay: calc(-400s / 30);
            }
        }
        @keyframes shooting {
            0% {
                opacity: 0;
                transform: translateY(-300px);
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                transform: translateY(600px);
            }
        }
        .st0 {
            fill: #0490ce;
        }
        .st1 {
            fill: #E8E6EF;
        }
        .st2 {
            filter: url(#Adobe_OpacityMaskFilter);
        }
        .st3 {
            filter: url(#Adobe_OpacityMaskFilter_1_);
        }
        .st4 {
            mask: url(#SVGID_1_);
            fill: url(#SVGID_2_);
        }
        .st5 {
            opacity: 0.61;
            fill: #CFE0E2;
        }
        .st6 {
            opacity: 0.7;
            fill: #012226;
        }
        .st7 {
            filter: url(#Adobe_OpacityMaskFilter_2_);
        }
        .st8 {
            filter: url(#Adobe_OpacityMaskFilter_3_);
        }
        .st9 {
            mask: url(#SVGID_3_);
            fill: url(#SVGID_4_);
        }
        .st10 {
            fill: url(#SVGID_5_);
        }
        .st11 {
            fill: #00b4e5;
        }
        .st12 {
            fill: #0087a8;
        }
        // footer animation css end
    }
    .footer-bottom {
        background: #2a3756;
        .footer-cont {
            height: 60px;
            align-items: center;
            .logo-sec {
                ul {
                    display: flex;
                    list-style: none;
                    align-items: center;
                    margin-bottom: 0;
                    padding-left: 0;
                    li {
                        padding-right: 15px;
                    }
                    .bottom-left-logo1 {
                        background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
                        background-size: 1121px;
                        display: block;
                        height: 30px;
                        width: 20px;
                        background-position: -577px -1px;
                    }
                    .bottom-left-logo2 {
                        background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
                        display: block;
                        height: 26px;
                        width: 63px;
                        background-position: -808px -3px;
                    }
                    .bottom-left-logo3 {
                        background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
                        display: block;
                        height: 28px;
                        width: 64px;
                        background-position: -890px 0px;
                    }
                    .bottom-left-logo4 {
                        background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
                        display: block;
                        height: 28px;
                        width: 64px;
                        background-position: -970px 0px;
                    }
                    .bottom-left-logo5 {
                        background-image: url(#{$cloudfrontpath}/home/sevice-sprite-microsoft.png);
                        display: block;
                        height: 28px;
                        width: 43px;
                        background-position: -1053px 0px;
                    }
                }
            }
            .content {
                margin-left: auto;
                ul.copyrights {
                    li {
                        float: right;
                        list-style: none;
                        padding: 0 9px;
                        position: relative;
                        font-family: "Poppins", sans-serif;
                        font-display: swap;
                        color: #fff;
                        font-size: 13px;
                        font-weight: 400;
                        a {
                            color: #fff;
                        }
                        &:last-child {
                            &::before {
                                display: none;
                            }
                        }
                        &::before {
                            content: "";
                            height: 15px;
                            left: 0;
                            position: absolute;
                            top: 2px;
                            width: 1px;
                            background-color: #fff !important;
                        }
                    }
                }
            }
        }
    }
    .follow-us {
        .icon-sec {
            display: flex;
            margin-bottom: 0px;
            ul {
                display: flex;
                align-items: center;
                margin-bottom: 0px;
                list-style: none;
                li {
                    padding-bottom: 0;
                    padding-right: 15px;
                    padding-left: 0;
                    margin-bottom: 0px;
                    &::before {
                        position: static;
                    }
                    i {
                        color: #fff;
                        &:hover {
                            color: #2a3756;
                        }
                        &.fa-facebook {
                            background-image: url(#{$cloudfrontpath}/footer/facebook.svg);
                            width: 15px;
                            display: block;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            &:hover {
                                background-image: url(#{$cloudfrontpath}/footer/facebook-hover.svg);
                            }
                        }
                        &.fa-linkedin {
                            background-image: url(#{$cloudfrontpath}/footer/linkedin.svg);
                            width: 18px;
                            display: block;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            &:hover {
                                background-image: url(#{$cloudfrontpath}/footer/linkedin-hover.svg);
                            }
                        }
                        &.fa-twitter {
                            background-image: url(#{$cloudfrontpath}/footer/twitter.svg);
                            width: 22px;
                            display: block;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            &:hover {
                                background-image: url(#{$cloudfrontpath}/footer/twitter-hover.svg);
                            }
                        }
                        &.fa-dribble {
                            background-image: url(#{$cloudfrontpath}/footer/dribble.svg);
                            width: 22px;
                            display: block;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            &:hover {
                                background-image: url(#{$cloudfrontpath}/footer/dribble-hover.svg);
                            }
                        }
                        &.fa-behance {
                            background-image: url(#{$cloudfrontpath}/footer/behance.svg);
                            width: 22px;
                            display: block;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-size: contain;
                            &:hover {
                                background-image: url(#{$cloudfrontpath}/footer/behance-hover.svg);
                            }
                        }
                    }
                }
            }
        }
    }
}
.current-openings-parent{
    .main-footer{
        padding-top: 0;
        padding-bottom: 20px;
    }
}

.contact-footer {
    .main-footer {
        .socialicons {
            .social-field {
                &.email-icon {
                    top: 70px;
                    right: 90px;
                }
                &.support-icon {
                    top: 180px;
                    right: 50px;
                }
                &.skype-icon {
                    bottom: 20px;
                    right: 210px;
                }
                &.connectwith-icon {
                    display: none !important;
                }
            }
        }
    }
    .contact-socialmedia {
        background: #f7f7f7;
        padding: 40px 0px;
        .conatct-mediaicon {
            // min-width: 230px;
            text-transform: uppercase;
            color: #878787;
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            align-items: center;
            margin-bottom:10px; 
            &.facebook {
                .icon-imgmedia {
                    img {
                        width: 15px;
                    }
                }
            }
            .icon-imgmedia {
                min-width: 40px;
                // min-width: 60px;
                background: #0d2064;
                height: 100%;
                border-radius: 18px 0px 0px 18px;
                display: flex;
                justify-content: center;
                position: relative;
                align-items: center;
                &::after {
                    content: " ";
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-top: 14px solid transparent;
                    border-bottom: 14px solid transparent;
                    border-left: 16px solid #0d2064;
                    right: -14px;
                }
                img {
                    width: 25px;
                }
            }
            .content-imgmedia {
                padding: 8px 20px;
                width: 100%;
                border-top: 2px solid #ccc;
                border-bottom: 2px solid #ccc;
                border-right: 2px solid #ccc;
                border-radius: 0px 20px 20px 0px;
                p {
                    margin: 0;
                    font-size: 13px;
                }
                h4 {
                    margin: 0;
                    color: #0d2064;
                    font-weight: 600;
                    font-size: 17px;
                }
            }
            &.facebook {
                .icon-imgmedia {
                    background: #135faa;
                    &::after {
                        border-left: 16px solid #135faa;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #135faa;
                    }
                }
            }
            &.twitter {
                .icon-imgmedia {
                    background: #0099cd;
                    &::after {
                        border-left: 16px solid #0099cd;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #0099cd;
                    }
                }
            }
            &.insta {
                .icon-imgmedia {
                    background: #c745b1;
                    &::after {
                        border-left: 16px solid #c745b1;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #c745b1;
                    }
                }
            }
            &.youtubemedia {
                .icon-imgmedia {
                    background: #ff0a05;
                    &::after {
                        border-left: 16px solid #ff0a05;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #ff0a05;
                    }
                }
            }
            &.pinterestmedia {
                .icon-imgmedia {
                    background: #e60023;
                    &::after {
                        border-left: 16px solid #e60023;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #e60023;
                    }
                }
            }
            &.dribbleemedia {
                .icon-imgmedia {
                    background: #ec498a;
                    &::after {
                        border-left: 16px solid #ec498a;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #ec498a;
                    }
                }
            }
            &.behanceemedia {
                .icon-imgmedia {
                    background: #1d76e9;
                    &::after {
                        border-left: 16px solid #1d76e9;
                    }
                }
                .content-imgmedia {
                    h4 {
                        color: #1d76e9;
                    }
                }
            }
        }
    }
}

.grayline-footer {
    text-align: left;
    padding: 10px 0 40px;
    font-size: 14px;
    color: #fff;
    display: block;
    clear: both;
    background: #2a3756;
    margin-top: -15px;
    border-top: 1px solid #8e8e8e;
    p {
        font-size: 14px;
    }
    span {
        color: red;
    }
    a {
        font-weight: 600;
        color: #5eb5e7;
    }
}

@media screen and (max-width: 1599px) and (min-width: 1366px) {
    .innerpage-footer {
        .footer-blueline {
            h4 {
                font-size: 22px;
            }
            .btn-defualt {
                font-size: 16px;
                padding: 15px 40px;
            }
        }
        .main-footer {
            padding: 40px 0px;
            background-size: 8%;
            h3 {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .footer-form {
                .btn-blue {
                    padding: 12px 20px;
                    font-size: 16px;
                    float: right;
                }
            }
        }
        // .footer-bottom {
        //     padding-bottom: 20px;
        // }
    }
}

@media screen and (max-width: 1365px) and (min-width: 1200px) {
    .innerpage-footer {
        .footer-blueline {
            h4 {
                font-size: 22px;
            }
            .btn-defualt {
                font-size: 16px;
                padding: 15px 40px;
            }
        }
        .main-footer {
            padding: 40px 0px;
            background-size: 8%;
            h3 {
                font-size: 20px;
                margin-bottom: 20px;
            }
            .footer-form {
                .btn-blue {
                    padding: 12px 20px;
                    font-size: 16px;
                    float: right;
                }
            }
            .follow-us {
                .blue-sec {
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        .footer-bottom {
            padding-bottom: 30px;
        }
    }
}

@media screen and (min-width: 1200px) {
    .lets-work-margin {
        padding-left: 100px;
    }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
    .innerpage-footer {
        .footer-blueline {
            h4 {
                font-size: 18px;
            }
            .btn-defualt {
                font-size: 15px;
                padding: 12px 22px;
            }
        }
        .main-footer {
            padding: 30px 0px;
            background-size: 9%;
            background-position: right 82%;
            h3 {
                font-size: 18px;
                margin-bottom: 20px;
            }
            p {
                font-size: 14px;
            }
            .socialicons {
                .social-field {
                    padding-left: 50px;
                    h2 {
                        font-size: 15px;
                        margin-bottom: 5px;
                    }
                    h3 {
                        margin-top: 5px;
                        font-size: 14px;
                    }
                    &.email-icon {
                        top: 55px;
                        right: 65px;
                    }
                    &.support-icon {
                        top: 130px;
                        right: 10px;
                    }
                    &.skype-icon {
                        bottom: 70px;
                        right: 125px;
                    }
                    &.connectwith-icon {
                        bottom: 0px;
                        right: 65px;
                    }
                }
            }
            .footer-form {
                .btn-blue {
                    padding: 12px 20px;
                    font-size: 15px;
                    float: right;
                }
            }
            .follow-us {
                .blue-sec {
                    p {
                        font-size: 16px;
                    }
                }
                .icon-sec {
                    ul {
                        li {
                            i {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            ul {
                li {
                    padding-left: 30px;
                    font-size: 14px;
                }
            }
            .artboard {
                width: 280px;
                height: 280px;
            }
        }
        .footer-quicklink {
            padding: 30px 70px 20px;
            .follow-us {
                .icon-sec {
                    ul {
                        padding-left: 13px;
                        li {
                            padding-left: 15px;
                            &:first-child {
                                padding-left: 0;
                            }
                            i {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
        .footer-bottom {
            padding-bottom: 30px;
            .footer-cont {
                padding: 0 60px;
            }
        }
    }
    .contact-footer {
        .main-footer {
            .socialicons {
                .social-field {
                    &.email-icon {
                        top: 80px;
                        right: 35px;
                    }
                    &.support-icon {
                        top: 180px;
                        right: 5px;
                    }
                    &.skype-icon {
                        bottom: 10px;
                        right: 160px;
                    }
                }
            }
        }
        .contact-socialmedia {
            .justify-content-between {
                justify-content: center !important;
                ;
            }
            .conatct-mediaicon {
                min-width: auto;
                margin: 7px;
                .content-imgmedia {
                    width: 80%;
                    padding: 10px 20px;
                    p {
                        font-size: 12px;
                    }
                    h4 {
                        font-size: 14px;
                    }
                }
                .icon-imgmedia {
                    min-width: 50px;
                    img {
                        height: 28px;
                    }
                }
            }
        }
    }
    .grayline-footer {
        text-align: center;
        padding: 10px 90px 60px;
    }
}

@media screen and (max-width: 991px) {
    .innerpage-footer {
        .contact-socialmedia {
            .justify-content-between {
                justify-content: center !important;
            }
        }
        .footer-blueline {
            h4 {
                font-size: 18px;
            }
            .btn-defualt {
                font-size: 15px;
                padding: 12px 22px;
            }
        }
        .main-footer {
            padding: 30px 0px;
            background: none;
            h3 {
                font-size: 18px;
                margin-bottom: 20px;
                padding-top: 25px;
                border-top: 1px solid #ccc;
                text-align: center;
                &.no-border {
                    border: 0px;
                    padding-top: 0px;
                }
            }
            p {
                font-size: 14px;
                text-align: center;
            }
            .socialicons {
                .social-field {
                    padding-left: 50px;
                    h2 {
                        font-size: 15px;
                        margin-bottom: 5px;
                    }
                    h3 {
                        margin-top: 5px;
                        font-size: 14px;
                    }
                    &.email-icon {
                        top: 70px;
                        right: 75px;
                    }
                    &.support-icon {
                        top: 140px;
                        right: 30px;
                    }
                    &.skype-icon {
                        bottom: 70px;
                        right: 140px;
                    }
                    &.connectwith-icon {
                        bottom: 0px;
                        right: 70px;
                    }
                }
            }
            .artboard {
                display: block;
            }
            .footer-form {
                .btn-blue {
                    padding: 12px 20px;
                    font-size: 15px;
                    margin-bottom: 30px;
                    float: left;
                }
            }
            .follow-us {
                .blue-sec {
                    p {
                        font-size: 16px;
                    }
                }
                .icon-sec {
                    ul {
                        li {
                            i {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
            ul {
                li {
                    padding-left: 30px;
                    font-size: 14px;
                }
            }
        }
        .footer-quicklink {
            padding: 20px 50px;
            ul {
                display: inline-block;
                margin-bottom: 10px;
            }
            .follow-us {
                ul {
                    padding-left: 14px;
                    li {
                        &:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            padding-bottom: 30px;
            .footer-cont {
                display: block !important;
                height: auto;
                padding: 0 20px;
                .logo-sec {
                    text-align: center;
                    ul {
                        display: inline-flex;
                        margin-top: 10px;
                    }
                }
                .content {
                    display: block;
                    margin-bottom: 10px;
                    ul {
                        &.copyrights {
                            text-align: center;
                            padding-left: 0;
                            li {
                                float: none;
                                display: inline-block;
                                &::before {
                                    left: unset;
                                    right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .contact-footer {
        .main-footer {
            .socialicons {
                .social-field {
                    &.email-icon {
                        top: 80px;
                        right: 60px;
                    }
                    &.support-icon {
                        top: 180px;
                        right: 25px;
                    }
                    &.skype-icon {
                        bottom: 20px;
                        right: 175px;
                    }
                }
            }
        }
        .contact-socialmedia {
            padding: 20px 0px;
            .conatct-mediaicon {
                min-width: auto;
                margin: 7px 5px;
                .content-imgmedia {
                    width: 80%;
                    padding: 10px 20px;
                    p {
                        display: none;
                    }
                    h4 {
                        font-size: 12px;
                    }
                }
                .icon-imgmedia {
                    min-width: 40px;
                    img {
                        height: 16px;
                    }
                }
            }
        }
    }
    .grayline-footer {
        text-align: center;
        padding: 10px 60px 40px;
    }
}

//   end new footer css
